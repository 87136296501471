.app-layout {
  min-height: 100vh;
  @include base-background;
  .ant-layout {
    @include base-background;
  }
  .app-layout-logo {
    background: $new-gray;
    height: 64px;
    padding: 16px;
    text-align: center;
    display: block;
    cursor: pointer;
    svg {
      height: 30px;
    }
  }
  aside,
  .ant-layout-sider-trigger,
  .ant-menu {
    background: $new-gray;
  }
  .ant-menu-item:first-child {
    margin-top: 0;
  }
  .ant-menu-item-selected {
    background-color: $blue !important;
  }
  .ant-menu {
    svg {
      font-size: 24px;
      margin-bottom: -8px;
      margin-left: -2px;
    }
  }
  header {
    background: $new-gray;
    padding: 0 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
    align-items: center;
    line-height: .6em;
    color: #FFF;

    .dealer-info {
      display: flex;
      align-items: center;

      > svg {
        margin-top: 15px;
      }
    }

    .partner-autocomplete {
      margin-left: 10px;
      margin-right: 30px;
      width: 250px;

      > span + div,
      > span + div * {
        background: transparent;
        color: $white;
        line-height: 14px;
      }

      > span + div + div,
      > span + div + div * {
        background: black;
        color: $white;
        line-height: 14px;
      }

      > div:last-child {
        z-index: 9;
      }
    }

    > div > .anticon {
      margin: 0 15px;

      svg {
        fill: #FFF;
        font-size: 18px;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .ant-calendar-picker .anticon {
      background: rgb(0, 0, 0);
    }
  }
  main {
    margin: 0;
  }
  footer {
    text-align: center;
    background: transparent;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75));
    div {
      display: inline-flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      width: 320px;
    }
    svg {
      fill: #FFF;
      font-size: 18px;
    }
    i:first-child svg {
      fill: $blue;
    }
  }
}

.main-spinner {
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% - 16px);
}

.main-error-message {
  color: $white;
  text-align: center;
  margin-top: 120px;
}

.ant-drawer-content {
  @include base-background;
  p {
    cursor: pointer;
  }
}

.ant-layout-header {
  .anticon-menu-fold {
    display: none;
  }
}

.ant-layout-sider {
  display: none
}

@media only screen and (max-width: 768px) {
  .ant-layout-sider {
    display: none
  }
}

@media only screen and (max-width: 600px) {
  .ant-layout-header {
    .drive-logo {
      display: none;
    }
  }
}

.ant-layout-header {
  .anticon-search,
  .anticon-setting,
  .anticon-alert {
    display: none;
  }
  .anticon-menu-fold {
    display: none;
  }
}

@media only screen and (max-width: 448px) {
  .ant-layout-header {
    .anticon-search,
    .anticon-setting,
    .anticon-alert {
      display: none;
    }
    .anticon-menu-fold {
      display: none;
    }
  }
}

.success-modal-root {
  .ant-modal-content {
    @include light-background-gradient;
    color: $white;
  }
  .ant-modal-body {
    padding: 15px 30px;
  }
  .ant-modal-header,
  .ant-modal-footer {
    border: 0;
    background: transparent;
    color: $white;
  }
  h2 {
    color: $white;
    font-size: 20px;
    line-height: 30px;
  }
  strong {
    font-size: 16px;
    line-height: 30px;
  }
  a {
    line-height: 30px;
  }
  .ant-modal-title {
    color: $white;
    font-size: 20px;
    line-height: 30px;
  }
  .ant-modal-footer {
    padding-bottom: 30px;
  }
}