.campaign-creator-root {
  .ant-select.ant-select-enabled{
    float: none !important;
  }
  .steps-action {
    text-align: center;
    padding: 15px 0 40px;
  }
  .campaign-review-cart {
    padding: 0 0 40px 40px;
  }
  .ant-modal-content {
    @include light-background-gradient;
    color: $white;
  }
  .ant-modal-body {
    padding: 0;
  }
  .wrapper-header {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px 20px;

    .ant-card-meta-title {
      color: $white;
      font-size: 20px;
      margin-bottom: 0;
    }
  }
  .ant-tabs-tabpane,
  .anticon-close,
  .ant-tabs-tab,
  h1,
  h2,
  h3 {
    color: $white;
  }
  .ant-tabs-tab {
    &:hover {
      color: $blue;
    }
  }
  .ant-tabs-ink-bar {
    background-color: $blue;
  }
  .ant-modal-header,
  .ant-modal-footer {
    border: 0;
    background: transparent;
    color: $white;
  }
  .ant-modal-title {
    color: $white;
    font-size: 20px;
    line-height: 30px;
  }
  .summary-view {
    margin: 0 -24px;
  }
  .ant-modal-footer {
    button {
      border-color: $white;
      background: $campaign-creator-back;
      color: $white;
      span {
        background: $campaign-creator-back;
      }
      &[disabled] {
        color: $white;
        opacity: 0.4;
      }
    }
    button.ant-btn-primary {
      border-color: $blue;
      background: $blue;
      span {
        background: $blue;
      }
    }
  }
  .view-check-list {
    * {
      color: $white;
    }
    .ant-avatar-circle {
      height: 34px;
      width: 34px;
      margin-top: 7px;
      svg {
        margin-top: -2px;
        margin-left: -4px;
      }
    }
    .ant-checkbox-wrapper {
      margin-right: 10px;
    }
  }
  .manual-budget {
    .ant-input-number {
      width: 50%;
    }
  }
  .social-media-list {
    .ant-avatar-circle {
      width: 40px;
      height: 40px;
      svg {
        width: 40px;
        height: 40px;
      }
    }
    .space-between-row {
      color: $white;
      padding: 10px 5px;
      .ant-card-meta-avatar {
        padding-right: 10px;
      }
      .ant-card-meta-detail {
        line-height: 1rem;
        .ant-card-meta-title {
          @include medium-font;
          color: $white;
          margin-top: 8px;
        }
      }
    }
  }
  .template-image {
    text-align: center;
    img {
      width: 100px;
    }
  }
  .video-upload {
    .ant-checkbox-wrapper {
      color: $white;
      margin-right: 10px;
    }
    .ant-upload-list-item {
      * {
        color: $white;
      }
      .ant-upload-list-item-card-actions {
        a:last-child {
          display: none;
        }
      }
      &:hover {
        .ant-upload-list-item-info {
          background-color: transparent;
        }
      }
    }
  }
  .template-name {
    color: $white;
  }
  .template-selected {
    border: 2px solid $white;
  }
  .subscribe {
    color: $white;
    display: inline-flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    width: calc(100% - 332px);
  }
  .media-item {
    padding-bottom: 20px;

    h3 {
      text-align: center;
      font-size: 18px;
      padding: 0 0 10px;
    }

    .ant-list-item-meta-content {
      padding-top: 0 !important;
    }
  }
  .package-radio {
    margin-top: 10px;
    float: right;
  }
  .schedule-step {
    text-align: center;
    h3 {
      font-size: 18px;
      padding: 0 0 30px;
    }
    .date-picker-cell {
      width: 300px;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      align-items: center;
      padding-bottom: 20px;

      + .pf-c-switch {
        padding: 10px 0 30px;

        + p {
          width: 300px;
          margin: 0 auto;
          font-size: 12px;
          padding-bottom: 60px;
        }
      }
    }
  }
  .pf-c-switch__label.pf-m-on {
    color: $white !important;
  }
  .package-channels {
    display: flex;

    > span {
      margin-left: 10px;
    }
  }
  .wrapper-header-details {
    color: $white;
    font-size: 12px;
    padding-bottom: 8px;
    display: flex;
    justify-content: space-between;
    width: 450px;

    .wrapper-header-details-totals {
      display: flex;
      flex-direction: column;
      align-items: center;

      span:last-child {
        font-size: 18px;
        margin-top: 8px;
      }
    }
  }
  .evergreen-videos {
    width: 550px;
    padding-left: 30px;
    padding-bottom: 30px;

    h3 {
      font-size: 18px;
      padding: 0 0 10px;
    }

    > div {
      .pf-c-tile {
        padding: 10px;
      }
    }

    > div:last-child {
      padding-top: 15px;
      .pf-c-tile {
        margin: 10px;
      }
    }
  }
  .publisher-options-list{
    color: $white;
    padding: 16px;

    .publisher-options-list-item {
      border-bottom: 1px solid $white;
      margin-bottom: 20px;

      &:last-child {
        border-bottom: 0;
      }

      > div {
        padding-bottom: 20px;
      }
    }

    h6 {
      color: $white;
      font-size: 18px;
      font-weight: 700;
    }
    p {
      font-size: 16px !important;
    }

    .ant-select {
      width: 100% !important;
      float: none !important;
    }
  }
  .header-select{
    text-align:right;
    margin-right:5%;
  }
  @media only screen and (max-width: 576px) {
    .subscribe {
      display: block;
      width: 100%;
      margin-top: 20px;
      @include large-font;
    }
  }
}

.pf-c-calendar-month__dates-cell.pf-m-disabled {
  opacity: .5;
}
.pf-c-calendar-month__date:hover, .pf-c-calendar-month__date:focus {
  background: #000;
}

.video-upload-tile {
  vertical-align: top;

  > div:first-child {
    width: 150px;
    height: 88px;
    justify-content: center;
  }
}

.MuiDialog-root h2 {
  color: $white;
}

.uploading-video-progress {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999999999999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview-video {
  text-align: center;

  h3 {
    padding-bottom: 15px;
  }
}
