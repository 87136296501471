// provided by will
// .dashboard-container .signature-pad canvas {
//   margin: 0 auto;
//   background: #000000;
//   opacity: 35%;
// }

// .ant-tabs-nav {
//   position: relative;
//   display: inline-block;
//   -webkit-box-sizing: border-box;
//   box-sizing: border-box;
//   margin: 0;
//   padding-left: 10px;
//   list-style: none;
//   -webkit-transition: -webkit-transform .3s cubic-bezier(.645,.045,.355,1);
//   transition: -webkit-transform .3s cubic-bezier(.645,.045,.355,1);
//   transition: transform .3s cubic-bezier(.645,.045,.355,1);
//   transition: transform .3s cubic-bezier(.645,.045,.355,1),-webkit-transform .3s cubic-bezier(.645,.045,.355,1);
// }

// .dashboard-container .signature-pad {
//   overflow: hidden;
//   display: none;
// }

// /* Schedule/Perfomance Chart - Graphic Version */
// .optimizations-card img {
//   width: 100% !important;
//   height: auto !important;
// }

// .app-layout .ant-menu svg {
//   font-size: 45px;
//   margin-bottom: -8px;
//   margin-left: -2px;
// }

// .ant-menu-inline .ant-menu-item:not(:last-child), .ant-menu-vertical-left .ant-menu-item:not(:last-child), .ant-menu-vertical-right .ant-menu-item:not(:last-child), .ant-menu-vertical .ant-menu-item:not(:last-child) {
//   margin-bottom: 15px;
// }

// .dashboard-container .your-order-card .card-scroll-content {
//   height: 250px;
//   position: relative;
//   overflow-y: scroll;
//   -webkit-overflow-scrolling: touch;
//   font-size: 1.2rem;
// }

// .dashboard-container .your-order-card {
//   background-color: #000000;
//   color: #fff;
//   opacity: 55%;
// }

// .ant-list-item-meta-title {
//   margin-bottom: 0px;
//   color: rgba(0,0,0,.65);
//   font-size: 18px;
//   line-height: 22px;
//   vertical-align: middle;
// }

// .tab-card .ant-tabs-tab {
//   font-size: 1.1rem;
//   color: #fff;
//   border: 1px solid #fff;
//   padding: 2px 10px;
//   border-radius: 20px;
//   background: #242a31;
//   margin-right: 10px;
// }

// .dashboard-container .social-media-list .space-between-row .ant-card-meta-detail {
//   line-height: 1.1rem;
// }

// .dashboard-container .social-media-list .space-between-row .ant-card-meta-detail .ant-card-meta-title {
//   margin-bottom: 0;
//   font-size: 1.1rem;
// }

// .dashboard-container .social-media-list .space-between-row .ant-card-meta-detail .ant-card-meta-description {
//   color: #fff;
//   margin-left: 2px;
//   font-size: 1rem;
// }

// .dashboard-container .social-media-list .space-between-row .social-data {
//   font-size: 1.1rem;
//   line-height: .8rem;
//   margin-top: 2px;
//   margin-right: 25px;
// }

// .dashboard-container .social-media-list .space-between-row .anticon-ellipsis {
//   position: absolute;
//   top: 0;
//   right: 5px;
//   font-size: 1.5rem;
// }

// .ant-card.pie-chart-card .ant-card-meta-title {
//   color: #fff;
//   margin-top: 0px;
//   font-size: 1.5rem;
// }

// .tab-card .ant-card-meta-title {
//   color: #fff;
//   margin-top: 0px;
//   font-size: 1.5rem;
//   padding-left: 0px;
// }

// .ant-card-small>.ant-card-head>.ant-card-head-wrapper>.ant-card-head-title {
//   padding: 10px 10px;
// }

// .ant-tabs-nav-container {
//   position: relative;
//   -webkit-box-sizing: border-box;
//   box-sizing: border-box;
//   margin-bottom: -1px;
//   overflow: hidden;
//   font-size: 18px;
//   line-height: 1.5;
//   white-space: nowrap;
//   zoom: 1;
//   padding: 10px 10px;
// }

// .dashboard-container .optimizations-card .ant-tabs-nav-scroll {
//   padding-left: 10px;
//   padding: inherit;
// }

// .dashboard-container .dynamic-video-row {
//   font-size: 1.3rem;
//   padding-bottom: 5px;
//   border-bottom: 1px solid #fff;
//   margin-bottom: 5px;
// }

// .dashboard-container .dynamic-video-row .video-data-text {
//   font-size: 1rem;
//   padding-bottom: 10px;
// }

// .dashboard-container .dynamic-video-row {
//   font-size: 1.3rem;
//   padding-top: 10px;
//   padding-bottom: 5px;
//   border-bottom: 1px solid #fff;
//   margin-bottom: 5px;
// }

// .ant-card-small>.ant-card-head {
//   min-height: 36px;
//   padding: 0 12px;
//   font-size: 20px;
// }

// .app-layout header svg {
//   fill: #fff;
//   font-size: 25px;
// }

// .dashboard-container .social-media-list .space-between-row {
//   position: relative;
//   color: #fff;
//   padding: 15px 15px;
// }

/* WW */
.app-layout .ant-menu svg {
	font-size: 44px !important;
	margin-bottom: -20px !important;
	margin-left: -15px !important;
}
.ant-list-item-meta-title {
	margin-bottom: 4px;
	color: #000000 !important;
	font-size: 18px !important;
	line-height: 24px !important;
}
.dashboard-container .car-list-extra {
	color: #fff;
	font-size: 1rem !important;
	line-height: 1.7rem !important;
	margin-top: -5px !important;
}
.ant-list-item-meta-content {
	-ms-flex: 1 0;
	flex: 1 0;
	/* top: 50%; */
	padding-top: 10px !important;
}
.ant-list-item-meta-title {
	margin-bottom: 4px !important;
	color: #000000 !important;
	font-size: 18px;
	font-weight: 700 !important;
	line-height: 24px !important;
}
.ant-card.pie-chart-card .ant-card-meta-title {
	color: #fff;
	margin-top: 5px;
	margin-bottom: 10px !important;
	font-size: 1.2rem !important;
}
.dashboard-container .your-inventory-title {
	justify-content: flex-start;
	font-size: 1.2rem !important;
	line-height: 30px;
	margin-top: 0px;
	height: auto;
	min-height: 36px;
	padding: 17px 15px !important;
	border-bottom: 1px solid #ffffff;
}
.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
	padding: 15px 0px;
}
.tab-card .ant-card-meta-title {
	color: #fff;
	margin-top: 5px;
	margin-bottom: 10px !important;
	font-size: 1.2rem !important;
}
.space-between-row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px 15px !important;
}
.dashboard-container .your-order-card .card-scroll-content {
	height: 333px;
	position: relative;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	font-size: 1rem !important;
}
.dashboard-container .your-order-card .card-scroll-content {
	height: 333px;
	position: relative;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	font-size: 1rem !important;
}
.ant-list-split .ant-list-item {
	border-bottom: 1px solid;
	border-color: #ffffff !important;
}
.ant-list-item {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 15px !important;
}
.ant-select-selection {
	display: block;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	background-color: #fff;
	border: 1px solid #d9d9d9;
	border-top-width: 1.02px;
	border-radius: 50px !important;
	outline: none;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.ant-btn-round.ant-btn-sm {
	height: 45px !important;
	padding: 0px 18px !important;
	font-size: 20px !important;
	border-radius: 50px !important;
}
.dashboard-container .your-order-card .card-scroll-content {
	font-size: 1rem !important;
}
.ant-avatar > img {
	display: block;
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	/* border: 2px solid #ffffff; */
}
.ant-avatar {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: #000000 !important;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5;
	list-style: none;
	-webkit-font-feature-settings: "tnum";
	font-feature-settings: "tnum", "tnum";
	position: relative;
	display: inline-block;
	overflow: hidden;
	color: #fff;
	white-space: nowrap;
	text-align: center;
	vertical-align: middle;
	background: rgba(250, 250, 250, 0.5) !important;
	width: 32px;
	height: 32px;
	line-height: 32px;
	border-radius: 50%;
	border: 2px solid #fff;
}
.dashboard-container .social-media-list .space-between-row .ant-card-meta-avatar {
	padding-right: 10px;
}
.dashboard-container .social-media-list .space-between-row .ant-card-meta-detail .ant-card-meta-description {
	color: #fff;
	margin-left: 2px;
	font-size: 0.8rem !important;
}
.dashboard-container .social-media-list .space-between-row .social-data {
	font-size: 0.8rem !important;
	line-height: 1rem;
	margin-top: 2px;
	margin-right: 10px;
}
.ant-list-item-meta-title {
	margin-bottom: 4px !important;
	color: #ffffff !important;
	font-size: 18px;
	font-weight: 700 !important;
	line-height: 24px !important;
}
.ant-list-split .ant-list-item {
	border-bottom: 1px solid;
	border-color: #444444 !important;
}
.dashboard-container .your-inventory-title {
	justify-content: flex-start;
	font-size: 1.2rem;
	line-height: 20px;
	margin-top: 0px;
	height: auto;
	min-height: 36px;
	padding: 23px 15px !important;
	border-bottom: 1px solid #444444;
}
.ant-card.pie-chart-card .pull-up div text {
	font-family: sans-serif;
	font-size: 15px !important;
	color: #ffffff !important;
	/*fill: rgba(255, 255, 255);*/
}
.ant-select-selection {
	display: block;
	box-sizing: border-box;
	background-color: transparent;
	border: none;
	border-top-width: 1.02px;
	border-radius: 0 !important;
	border-bottom: 1px solid #ffffff;
	outline: none;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-user-select: none;
	user-select: none;
}
.ant-select-selection a,
.ant-select-selection a:active,
.ant-select-selection a:hover,
.ant-select-selection a::after,
.ant-select-selection a::before {
	display: block;
	box-sizing: border-box;
	background-color: transparent;
	border: none !important;
	border-radius: 0 !important;
	border-bottom: 1px solid #ffffff;
	outline: none;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-user-select: none;
	user-select: none;
	color: #ffffff !important;
}
.ant-select {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: #ffffff !important;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5;
	list-style: none;
	-webkit-font-feature-settings: "tnum";
	font-feature-settings: "tnum", "tnum";
	position: relative;
	display: inline-block;
	outline: 0;
}
.dashboard-container .your-inventory-box,
.dashboard-container .top-ads-box {
	color: #fff;
}
.ant-select-selection {
	display: block;
	box-sizing: border-box;
	border: 0 !important;
	border-top-width: 1.02px;
	outline: none;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-user-select: none;
	user-select: none;
}
.space-between-row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px 15px !important;
	padding-bottom: 0px !important;
}
.ant-card.pie-chart-card .pull-up {
	margin-top: 15px;
	position: relative;
}
.ant-card.pie-chart-card .pull-up svg:not(:root) {
	overflow: visible;
}
.anticon {
	display: inline-block;
	color: #ffffff !important;
	line-height: 0;
	text-align: center;
	text-transform: none;
	vertical-align: -0.125em;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.antSelectLogo .ant-select-selection .ant-select-arrow i{
	color: black !important;
	background-color: white !important;

}
.selectedImagesBlock{
	margin-bottom: 30px;
}
.selectedImagesTable{
	width: 90%;
}
.selectionBlock{
	margin-top: 20px;
}
.selectionTable{
	width:90%;
}
.imageSelection{
	float:left;
	width:100%;
}
.selectNone{
	color: black;
}
.noImageText{
	color:black;
	font-size:20px !important;
	margin-left:10%;
}
.dashboard-container .dynamic-video-row .video-data-text {
	font-size: 0.7rem !important;
}
.ant-select-dropdown {
	margin: 0;
	padding: 0;
	color: #ffffff !important;
	font-variant: tabular-nums;
	line-height: 1.5;
	list-style: none;
	-webkit-font-feature-settings: "tnum";
	font-feature-settings: "tnum", "tnum";
	position: absolute;
	top: -9999px;
	left: -9999px;
	z-index: 1050;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	font-size: 14px;
	font-variant: initial;
	border-radius: 4px;
	outline: none;
	background-color: rgba(0, 0, 0, 0.95);
}
.dashboard-container .dashboard-row > div {
	height: 375px;
	overflow: hidden;
	padding: 15px;
}
/* TITLES */
.ant-card.pie-chart-card .ant-card-meta-title {
	color: #fff;
	margin-top: 5px;
	margin-bottom: 10px !important;
	font-size: 1.5rem !important;
	font-weight: 700;
}
.dashboard-container .your-inventory-title {
	justify-content: flex-start;
	font-size: 1.5rem !important;
	line-height: 40px;
	margin-top: 0px;
	height: auto;
	min-height: 36px;
	padding: 17px 15px !important;
	border-bottom: 1px solid #444444;
	font-weight: 700;
}
.tab-card .ant-card-meta-title {
	color: #fff;
	margin-top: 5px;
	margin-bottom: 10px !important;
	font-size: 1.5rem !important;
	font-weight: 700;
}
.dashboard-container .live-monitoring-text {
	background: transparent !important;
	font-size: 1.5rem;
	height: 55px;
	align-items: center;
	justify-content: center;
	font-weight: 700;
}
.dashboard-container .chart-top-text {
	font-size: 1.5rem;
	height: 55px;
	padding: 0 10px;
	font-weight: 700;
}
/*.ant-select-dropdown-menu-item-selected {
	color: #ffffff !important;
	font-weight: 600;
	background-color: rgba(0, 0, 0, 0.7) !important;
  }*/
.ant-select-dropdown-menu-item {
	position: relative;
	display: block;
	padding: 5px 12px;
	overflow: hidden;
	color: rgba(250, 250, 250, 0.65);
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
	white-space: nowrap;
	text-overflow: ellipsis;
	cursor: pointer;
	-webkit-transition: background 0.3s ease;
	transition: background 0.3s ease;
}
.ant-select-dropdown-menu-item-active,
.ant-select-dropdown-menu-item-selected {
	color: #ffffff !important;
	background-color: rgba(0, 0, 0, 0.85) !important;
}
.menu-item-disabled {
	background-color: rgba(0, 0, 0, 0.7) !important;
	color: #ffffff;
}
.ant-card-head {
	min-height: 48px;
	margin-bottom: -1px;
	padding: 0 24px;
	color: rgba(0, 0, 0, 0.85);
	font-weight: 500;
	font-size: 16px;
	background: transparent;
	border-bottom: 1px solid;
	border-color: rgba(250, 250, 250, 0.3) !important;
	border-radius: 2px 2px 0 0;
	zoom: 1;
}
/*body {
	background-image: url(https://img.autobytel.com/2021/honda/civic-type-r/2-800-oem-exterior-front1300-95369.jpg);
  }*/
/*.light-back {
	background: rgba(0, 0, 0, 0.5) !important;
	background-image: url(hhttps://img.autobytel.com/2021/honda/civic-type-r/2-800-oem-exterior-front1300-95369.jpg);
  }*/
.dashboard-container .your-inventory-box,
.dashboard-container .top-ads-box {
	background: transparent !important;
	color: #fff;
}
.dashboard-container .chart-top-text {
	background: transparent !important;
	font-size: 1.5rem;
	height: 55px;
	padding: 0 10px;
}
.dashboard-container .social-media-list {
	height: 400px;
	background: transparent !important;
}
.dashboard-container .optimizations-card {
	height: 515px;
	background: transparent !important;
}
.ant-card.pie-chart-card .center-data {
	font-size: 1rem;
	position: absolute;
	background: #444444;
	height: 98px;
	width: 98px;
	border-radius: 50%;
	top: calc(50% - 49px);
	left: calc(50% - 49px);
	z-index: 0;
	color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	z-index: 200;
}
.ant-row .ant-col div svg {
	background: transparent !important;
}
.dashboard-container .signature-pad canvas {
	margin: 0 auto;
	background: rgba(0, 0, 0, 0.7) !important;
	border: 1px solid;
	border-color: rgba(250, 250, 250, 0.5) !important;
}
.app-layout .ant-layout {
	background: transparent !important;
}
.app-layout {
	min-height: 100vh;
	background: rgba(0, 0, 0, 0.9) !important;
}
.dashboard-container .your-inventory-box .your-inventory-filter {
	display: flex;
	justify-content: space-between;
	padding-bottom: 15px !important;
	padding-top: 5px !important;
}
.tab-card .ant-tabs-top-bar {
	border: 0;
	padding: 15px 15px;
	margin-bottom: 0;
	border-bottom: 1px solid;
	border-color: rgba(250, 250, 250, 0.2) !important;
}
.dashboard-container .your-inventory-box .your-inventory-filter {
	display: flex;
	justify-content: space-between;
	padding-bottom: 15px !important;
	padding-top: 15px !important;
	border-bottom: 1px solid;
	border-color: rgba(250, 250, 250, 0.2) !important;
}
.dashboard-container .your-inventory-box .top-ads-filter,
.dashboard-container .top-ads-box .top-ads-filter {
	text-align: left;
	padding-bottom: 5px;
	border-bottom: 1px solid;
	border-color: rgba(250, 250, 250, 0.2) !important;
	padding-bottom: 25px !important;
}
.dashboard-container .optimizations-card .ant-tabs-nav-scroll {
	padding-left: 10px;
	padding-bottom: 15px !important;
}
.app-layout header {
	background: rgba(35, 35, 39, 0.9) !important;
	padding: 0 16px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	line-height: 0.6em;
	color: #fff;
}
.app-layout aside,
.app-layout .ant-layout-sider-trigger,
.app-layout .ant-menu {
	background: rgba(35, 35, 39, 0.9) !important;
}
.ant-select.ant-select-enabled,
.ant-select.ant-select-focussed {
	/* border: 0 !important; */
	border: 1px solid !important;
	border-color: rgba(250, 250, 250, 0.5) !important;
	border-radius: 50px !important;
	padding: 5px;
	-webkit-box-shadow: 0 !important;
	box-shadow: none !important;
}
.ant-select-open .ant-select-selection,
.ant-select-open .ant-select-selection:hover,
.ant-select-open,
.ant-select-focussed,
.ant-select-enabled,
.ant-select-selection__rendered,
.ant-select-enabled::selection {
	border: 0 !important;
	border-color: none !important;
}
.ant-select-open .ant-select-selection,
.ant-select,
.ant-select-enabled {
	-webkit-box-shadow: 0 !important;
	box-shadow: none !important;
}
.ant-select-selection {
	-webkit-box-shadow: 0 !important;
	box-shadow: none !important;
}
.ant-input {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-variant: tabular-nums;
	list-style: none;
	font-feature-settings: "tnum", "tnum", "tnum";
	position: relative;
	display: inline-block;
	width: 100%;
	height: 40px;
	padding: 4px 11px;
	color: #ffffff;
	font-size: 14px;
	line-height: 1.5;
	background-color: rgba(35, 35, 37, 0.8) !important;
	background-image: none;
	border: 1px solid;
	border-color: rgba(250, 250, 250, 0.4) !important;
	border-radius: 50px !important;
	transition: all 0.3s;
}
.app-layout .app-layout-logo svg {
	height: 50px !important;
}
.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu-inline-collapsed
	> .ant-menu-item-group
	> .ant-menu-item-group-list
	> .ant-menu-submenu
	> .ant-menu-submenu-title,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
	padding: 0 30px !important;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-vertical .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
	left: 0;
	margin-left: 0;
	border-right: 0;
	height: 65px;
	padding-top: 15px !important;
}
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
	margin-bottom: 0px;
	margin-top: 0px;
}
.ant-select-dropdown-menu {
	max-height: 250px;
	margin-bottom: 0;
	padding: 4px 0;
	padding-left: 0;
	overflow: auto;
	list-style: none;
	outline: none;
	border: 1px solid;
	border-color: rgba(250, 250, 250, 0.2) !important;
}
.dashboard-container .dynamic-video-row img {
	background: transparent;
	width: 95%;
	margin: 0 0 0 0;
	border: 1px solid;
	border-color: rgba(250, 250, 250, 0.4) !important;
}
.tab-card .ant-tabs-tab {
	font-size: 1rem !important;
	font-weight: 700 !important;
	color: #fff !important;
	border: 0;
	padding: 10px 15px !important;
	border-radius: 50px !important;
	background: rgba(250, 250, 250, 0.2);
	margin-right: 10px;
	min-width: 75px !important;
}
.tab-card .ant-tabs-tab-active {
	background: rgba(0, 136, 230, 0.9) !important;
	font-weight: 400;
}
.space-between-row.blue-back {
	background-color: rgba(0, 136, 230, 0.5);
	padding-bottom: 15px !important;
}
.dashboard-container .your-inventory-title,
.dashboard-container .top-performing-title {
	background: transparent !important;
	font-size: 1.5rem;
	height: 75px;
	align-items: center;
	color: #fff;
	display: flex;
	padding-left: 10px;
	margin: 0;
	justify-content: normal;
	text-align: left !important;
}
.dashboard-container .dynamic-video-row {
	font-size: 0.8rem;
	padding-bottom: 25px !important;
	padding-top: 25px !important;
	border-bottom: 1px solid;
	border-color: rgba(250, 250, 250, 0.2) !important;
	margin-bottom: 0 !important;
}
h3.top-performing-title {
	margin-bottom: 0px;
	padding-bottom: 0px;
	height: 55px !important;
}
/* DOUGHNUT CHART */
/*.ant-card.pie-chart-card .white-circle {
	position: absolute;
	background: #fff;
	height: 140px;
	width: 140px;
	border-radius: 50%;
	top: calc(50% - 70px) !important;
	left: calc(50% - 70px) !important;
	z-index: 0;
  }
  
  .ant-card.pie-chart-card .white-circle {
	position: absolute;
	background: #fff;
	height: 150px !important;
	width: 150px !important;
	border-radius: 50%;
	top: calc(50% - 75px) !important;
	left: calc(50% - 75px) !important;
	z-index: 0;
  }
  
  .ant-card.pie-chart-card .pull-up div svg {
	height: 180px !important;
	position: relative;
	z-index: 1;
  }*/
/* END DOUGHNUTS*/
.tab-card .ant-tabs-top-bar {
	border: 0;
	padding-bottom: 25px;
	margin-bottom: 0;
	border-bottom: 1px solid;
	border-color: rgba(250, 250, 250, 0.2) !important;
}
.dashboard-container .dashboard-row > div {
	height: 550px;
	overflow: hidden;
	padding: 15px;
	background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)) !important;
}
/*.dashboard-container .your-inventory-box .inventory-scroll-box {
	height: 450px !important;
  }
  
  .dashboard-container .dashboard-row > div {
	height: 450px !important;
	overflow: hidden;
	padding: 15px;
  }*/
/* ---------------------MODAL ---------------------- */
/* modal container */
.ant-modal.campaign-creator-root {
	width: 50% !important;
	border: 1px solid !important;
	border-color: rgba(250, 250, 250, 0.2) !important;
}
.campaign-creator-root ul.ant-menu.ant-menu-dark.ant-menu-root.ant-menu-vertical {
	background: rgba(260, 260, 260, 0.1) !important;
}
.campaign-creator-root ul.ant-menu.ant-menu-dark.ant-menu-root.ant-menu-vertical .ant-menu-item-selected {
	background-color: rgba(250, 250, 250, 0.2) !important;
	border: 1px solid;
	border-color: rgba(250, 250, 250, 0.3) !important;
	margin: 5px !important;
	border-radius: 50px;
	margin-top: 10px !important;
}
.campaign-creator-root .ant-modal-body > div,
.campaign-creator-root .ant-modal-body > div > div {
	width: 100% !important;
}
.ant-btn-primary {
	border-color: #00a6e6 !important;
	background: #00a6e6 !important;
	color: #fff !important;
	border-radius: 50px !important;
	min-width: 150px !important;
	font-size: 20px !important;
	padding: 0;
	height: 45px !important;
}
.ant-card-meta {
	width: 100% !important;
}
.ant-steps-dot .ant-steps-item-tail:after,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail:after {
	height: 7px !important;
	border-radius: 50px !important;
}
.ant-steps-dot .ant-steps-item-tail,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
	top: 1px !important;
}
.ant-btn {
	line-height: 1.499;
	position: relative;
	display: inline-block;
	font-weight: 700;
	white-space: nowrap;
	text-align: center;
	background-image: none;
	/*-webkit-box-shadow: 0 2px 0 rgba(0 0 0 / 2%);*/
	/*box-shadow: 0 2px 0 rgba(0 0 0 / 2%);*/
	cursor: pointer;
	-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	height: 32px;
	color: #777777;
	border-radius: 50px !important;
	min-width: 150px !important;
	font-size: 20px !important;
	height: 45px !important;
	vertical-align: middle !important;
}
.ant-checkbox-inner {
	position: relative;
	top: 0;
	left: 0;
	display: block;
	width: 35px;
	height: 35px;
	background-color: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	border-collapse: separate;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	border-radius: 50px !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
	background-color: #4fb057 !important;
	border-color: #4fb057 !important;
}
.dashboard-container .promote-btn-wrapper button.ant-btn.ant-btn-primary.ant-btn-round.ant-btn-sm {
	background-color: #4fb057 !important;
	border-color: #4fb057 !important;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: auto;
	margin-top: -10px !important;
}
.ant-btn-primary span {
	background: transparent !important;
}
.dashboard-container .your-inventory-title,
.dashboard-container .top-performing-title {
	background: transparent !important;
	font-size: 20px !important;
	height: 75px;
	align-items: center;
	color: #fff;
	display: flex;
	padding-left: 10px;
	margin: 0;
	justify-content: normal;
	text-align: left !important;
	font-weight: 700 !important;
}
.dashboard-container .promote-btn-wrapper {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding-top: 25px !important;
	/* padding-top: 55px !important; */
	margin-top: -20px !important;
	background: transparent !important;
	height: 1px !important;
	/*width: 100% !important;*/
	background-color: transparent !important;
	background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)) !important;
	padding-bottom: 55px !important;
}
.campaign-creator-root ul.ant-menu.ant-menu-dark.ant-menu-root.ant-menu-vertical .ant-menu-item-selected {
	background-color: rgba(250, 250, 250, 0.2) !important;
	border: 1px solid;
	border-color: rgba(250, 250, 250, 0.3) !important;
	margin: 5px !important;
	border-radius: 50px;
	margin-top: 10px !important;
	height: 45px !important;
	font-size: 20px !important;
	font-weight: 700 !important;
	line-height: 15px !important;
}
.pf-c-switch {
	--pf-c-switch__input--focus__toggle--OutlineColor: none !important;
}
/* STEP BUTTONS */
.campaign-creator-root .steps-action button.ant-btn {
	float: left !important;
}
.campaign-creator-root .steps-action button.ant-btn.ant-btn-primary {
	float: right !important;
}
.campaign-creator-root .steps-action {
	text-align: center;
	padding: 25px 25% 75px !important;
}
.pie-chart-carousel .slick-dots-bottom {
	bottom: -15px !important;
}
.ant-checkbox-checked:after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: none !important;
	border-radius: 50px !important;
	visibility: hidden;
	-webkit-animation: antCheckboxEffect 0.36s ease-in-out;
	animation: antCheckboxEffect 0.36s ease-in-out;
	-webkit-animation-fill-mode: backwards;
	animation-fill-mode: backwards;
	content: "";
}
.ant-checkbox-checked .ant-checkbox-inner:after {
	position: absolute;
	display: table;
	border: 2px solid #fff;
	border-top: 0;
	border-left: 0;
	-webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
	-ms-transform: rotate(45deg) scale(1) translate(-50%, -50%);
	transform: rotate(45deg) scale(1) translate(-50%, -50%);
	opacity: 1;
	-webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
	transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
	content: " ";
	width: 15px;
	height: 25px;
}
.pf-c-form-control {
	--pf-global--Color--100: none !important;
	--pf-global--Color--200: none !important;
	--pf-global--BorderColor--100: none !important;
	--pf-global--primary-color--100: none !important;
	--pf-global--link--Color: none !important;
	--pf-global--link--Color--hover: none !important;
	--pf-c-form-control--BorderTopColor: none !important;
	--pf-c-form-control--BorderRightColor: none !important;
	--pf-c-form-control--BorderBottomColor: none !important;
	--pf-c-form-control--BorderLeftColor: none !important;
	--pf-c-form-control--hover--BorderBottomColor: none !important;
	--pf-c-form-control--focus--BorderBottomWidth: var(--pf-global--BorderWidth--md);
	--pf-c-form-control--focus--PaddingBottom: calc(
		var(--pf-global--spacer--form-element) - var(--pf-c-form-control--focus--BorderBottomWidth)
	);
	--pf-c-form-control--focus--BorderBottomColor: none !important;
	--pf-c-form-control--m-expanded--BorderBottomWidth: var(--pf-global--BorderWidth--md);
	--pf-c-form-control--m-expanded--PaddingBottom: calc(
		var(--pf-global--spacer--form-element) - var(--pf-c-form-control--focus--BorderBottomWidth)
	);
	--pf-c-form-control--m-expanded--BorderBottomColor: none !important;
	--pf-c-form-control--placeholder--Color: none !important;
	--pf-c-form-control--disabled--Color: none !important;
	--pf-c-form-control--disabled--BackgroundColor: var(--pf-global--disabled-color--300);
	--pf-c-form-control--disabled--BorderColor: transparent !important;
	--pf-c-form-control--readonly--BackgroundColor: var(--pf-global--disabled-color--300);
	--pf-c-form-control--readonly--hover--BorderBottomColor: none !important;
	--pf-c-form-control--readonly--focus--PaddingBottom: calc(
		var(--pf-global--spacer--form-element) - var(--pf-global--BorderWidth--sm)
	);
	--pf-c-form-control--readonly--focus--BorderBottomWidth: var(--pf-global--BorderWidth--sm);
	--pf-c-form-control--readonly--focus--BorderBottomColor: none !important;
	--pf-c-form-control--success--BorderBottomWidth: var(--pf-global--BorderWidth--md);
	--pf-c-form-control--success--PaddingBottom: calc(
		var(--pf-global--spacer--form-element) - var(--pf-c-form-control--success--BorderBottomWidth)
	);
	--pf-c-form-control--success--BorderBottomColor: none !important;
	--pf-c-form-control--success--PaddingRight: var(--pf-global--spacer--xl);
	--pf-c-form-control--success--BackgroundPositionX: calc(100% - var(--pf-c-form-control--PaddingLeft));
	--pf-c-form-control--success--BackgroundPositionY: center;
	--pf-c-form-control--success--BackgroundPosition: var(--pf-c-form-control--success--BackgroundPositionX)
		var(--pf-c-form-control--success--BackgroundPositionY);
	--pf-c-form-control--success--BackgroundSizeX: var(--pf-c-form-control--FontSize);
	--pf-c-form-control--success--BackgroundSizeY: var(--pf-c-form-control--FontSize);
	--pf-c-form-control--success--BackgroundSize: var(--pf-c-form-control--success--BackgroundSizeX)
		var(--pf-c-form-control--success--BackgroundSizeY);
	--pf-c-form-control--success--BackgroundUrl: url(
		data:image/svg + xml;charset=utf8,
		%3Csvgxmlns="http://www.w3.org/2000/svg"viewBox="0 0 512 512"%3E%3Cpathfill="%233e8635"d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"/%3E%3C/svg%3E
	);
	--pf-c-form-control--m-warning--BorderBottomWidth: var(--pf-global--BorderWidth--md);
	--pf-c-form-control--m-warning--PaddingBottom: calc(
		var(--pf-global--spacer--form-element) - var(--pf-c-form-control--m-warning--BorderBottomWidth)
	);
	/* --pf-c-form-control--m-warning--BorderBottomColor: var(--pf-global--warning-color--100); */
	--pf-c-form-control--m-warning--PaddingRight: var(--pf-global--spacer--xl);
	--pf-c-form-control--m-warning--BackgroundPositionX: calc(100% - var(--pf-c-form-control--PaddingLeft) - 0.0625rem);
	--pf-c-form-control--m-warning--BackgroundPositionY: center;
	--pf-c-form-control--m-warning--BackgroundPosition: var(--pf-c-form-control--m-warning--BackgroundPositionX)
		var(--pf-c-form-control--m-warning--BackgroundPositionY);
	--pf-c-form-control--m-warning--BackgroundSizeX: 1.25rem;
	--pf-c-form-control--m-warning--BackgroundSizeY: var(--pf-c-form-control--FontSize);
	--pf-c-form-control--m-warning--BackgroundSize: var(--pf-c-form-control--m-warning--BackgroundSizeX)
		var(--pf-c-form-control--m-warning--BackgroundSizeY);
	--pf-c-form-control--m-warning--BackgroundUrl: url(
		data:image/svg + xml;charset=utf8,
		%3Csvgxmlns="http://www.w3.org/2000/svg"viewBox="0 0 512 512"%3E%3Cpathfill="%23f0ab00"d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"/%3E%3C/svg%3E
	);
	--pf-c-form-control--invalid--BorderBottomWidth: var(--pf-global--BorderWidth--md);
	--pf-c-form-control--invalid--PaddingBottom: calc(
		var(--pf-global--spacer--form-element) - var(--pf-c-form-control--invalid--BorderBottomWidth)
	);
	--pf-c-form-control--invalid--BorderBottomColor: none !important;
	--pf-c-form-control--invalid--PaddingRight: var(--pf-global--spacer--xl);
	--pf-c-form-control--invalid--BackgroundPositionX: calc(100% - var(--pf-c-form-control--PaddingLeft));
	--pf-c-form-control--invalid--BackgroundPositionY: center;
	--pf-c-form-control--invalid--BackgroundPosition: var(--pf-c-form-control--invalid--BackgroundPositionX)
		var(--pf-c-form-control--invalid--BackgroundPositionY);
	--pf-c-form-control--invalid--BackgroundSizeX: var(--pf-c-form-control--FontSize);
	--pf-c-form-control--invalid--BackgroundSizeY: var(--pf-c-form-control--FontSize);
	--pf-c-form-control--invalid--BackgroundSize: var(--pf-c-form-control--invalid--BackgroundSizeX)
		var(--pf-c-form-control--invalid--BackgroundSizeY);
	--pf-c-form-control--invalid--BackgroundUrl: url(
		data:image/svg + xml;charset=utf8,
		%3Csvgxmlns="http://www.w3.org/2000/svg"viewBox="0 0 512 512"%3E%3Cpathfill="%23c9190b"d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"/%3E%3C/svg%3E
	);
	--pf-c-form-control--invalid--exclamation--Background: var(--pf-c-form-control--invalid--BackgroundUrl)
		var(--pf-c-form-control--invalid--BackgroundPosition) / var(--pf-c-form-control--invalid--BackgroundSize)
		no-repeat;
	--pf-c-form-control--invalid--Background: var(--pf-c-form-control--BackgroundColor)
		var(--pf-c-form-control--invalid--exclamation--Background);
	--pf-c-form-control--m-search--PaddingLeft: var(--pf-global--spacer--xl);
	--pf-c-form-control--m-search--BackgroundPosition: var(--pf-c-form-control--PaddingRight);
	--pf-c-form-control--m-search--BackgroundSize: var(--pf-c-form-control--FontSize) var(--pf-c-form-control--FontSize);
	--pf-c-form-control--m-search--BackgroundUrl: url(
		data:image/svg + xml;charset=utf8,
		%3Csvgxmlns="http://www.w3.org/2000/svg"viewBox="0 0 512 512"%3E%3Cpathfill="%236a6e73"d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"/%3E%3C/svg%3E
	);
	--pf-c-form-control--m-icon--PaddingRight: calc(
		var(--pf-c-form-control--inset--base) + var(--pf-c-form-control--m-icon--BackgroundSizeX) +
			var(--pf-c-form-control--m-icon--icon--spacer)
	);
	--pf-c-form-control--m-icon--BackgroundUrl: none;
	--pf-c-form-control--m-icon--BackgroundPositionX: calc(100% - var(--pf-c-form-control--inset--base));
	--pf-c-form-control--m-icon--BackgroundPositionY: center;
	--pf-c-form-control--m-icon--BackgroundSizeX: var(--pf-c-form-control--FontSize);
	--pf-c-form-control--m-icon--BackgroundSizeY: var(--pf-c-form-control--FontSize);
	--pf-c-form-control--m-icon--icon--spacer: var(--pf-global--spacer--sm);
	--pf-c-form-control--m-icon--icon--PaddingRight: calc(
		var(--pf-c-form-control--inset--base) + var(--pf-c-form-control--invalid--BackgroundSizeX) +
			var(--pf-c-form-control--m-icon--icon--spacer) + var(--pf-c-form-control--m-icon--BackgroundSizeX) +
			var(--pf-c-form-control--m-icon--icon--spacer)
	);
	--pf-c-form-control--m-icon--icon--BackgroundPositionX: calc(
		var(--pf-c-form-control--m-icon--BackgroundPositionX) - var(--pf-c-form-control--m-icon--icon--spacer) -
			var(--pf-c-form-control--invalid--BackgroundSizeX)
	);
	--pf-c-form-control--m-icon--invalid--BackgroundUrl: var(--pf-c-form-control--invalid--BackgroundUrl),
		var(--pf-c-form-control--m-icon--BackgroundUrl);
	--pf-c-form-control--m-icon--invalid--BackgroundPosition: var(--pf-c-form-control--invalid--BackgroundPosition),
		var(--pf-c-form-control--m-icon--icon--BackgroundPositionX)
			var(--pf-c-form-control--m-icon--BackgroundPositionY);
	--pf-c-form-control--m-icon--invalid--BackgroundSize: var(--pf-c-form-control--invalid--BackgroundSize),
		var(--pf-c-form-control--m-icon--BackgroundSizeX) var(--pf-c-form-control--m-icon--BackgroundSizeY);
	--pf-c-form-control--m-icon--success--BackgroundUrl: var(--pf-c-form-control--success--BackgroundUrl),
		var(--pf-c-form-control--m-icon--BackgroundUrl);
	--pf-c-form-control--m-icon--success--BackgroundPosition: var(--pf-c-form-control--success--BackgroundPosition),
		var(--pf-c-form-control--m-icon--icon--BackgroundPositionX)
			var(--pf-c-form-control--m-icon--BackgroundPositionY);
	--pf-c-form-control--m-icon--success--BackgroundSize: var(--pf-c-form-control--success--BackgroundSize),
		var(--pf-c-form-control--m-icon--BackgroundSizeX) var(--pf-c-form-control--m-icon--BackgroundSizeY);
	--pf-c-form-control--m-icon--m-warning--BackgroundUrl: var(--pf-c-form-control--m-warning--BackgroundUrl),
		var(--pf-c-form-control--m-icon--BackgroundUrl);
	--pf-c-form-control--m-icon--m-warning--BackgroundPosition: var(--pf-c-form-control--m-warning--BackgroundPosition),
		var(--pf-c-form-control--m-icon--icon--BackgroundPositionX)
			var(--pf-c-form-control--m-icon--BackgroundPositionY);
	--pf-c-form-control--m-icon--m-warning--BackgroundSize: var(--pf-c-form-control--m-warning--BackgroundSize),
		var(--pf-c-form-control--m-icon--BackgroundSizeX) var(--pf-c-form-control--m-icon--BackgroundSizeY);
	--pf-c-form-control--m-calendar--BackgroundUrl: url(
		data:image/svg + xml;charset=utf8,
		%3Csvgxmlns="http://www.w3.org/2000/svg"viewBox="0 0 512 512"%3E%3Cpathfill="%236a6e73"d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z"/%3E%3C/svg%3E
	);
	--pf-c-form-control--m-clock--BackgroundUrl: url(
		data:image/svg + xml;charset=utf8,
		%3Csvgxmlns="http://www.w3.org/2000/svg"viewBox="0 0 512 512"%3E%3Cpathfill="%236a6e73"d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"/%3E%3C/svg%3E
	);
	--pf-c-form-control__select--PaddingRight: var(--pf-global--spacer--lg);
	--pf-c-form-control__select--BackgroundUrl: url(
		data:image/svg + xml;charset=utf8,
		%3Csvgxmlns="http://www.w3.org/2000/svg"viewBox="0 0 320 512"%3E%3Cpathfill="%23urrentColor"d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"/%3E%3C/svg%3E
	);
	--pf-c-form-control__select--BackgroundSize: 0.625em;
	--pf-c-form-control__select--BackgroundPositionX: calc(100% - var(--pf-global--spacer--md) + 1px);
	--pf-c-form-control__select--BackgroundPositionY: center;
	--pf-c-form-control__select--BackgroundPosition: var(--pf-c-form-control__select--BackgroundPositionX)
		var(--pf-c-form-control__select--BackgroundPositionY);
	--pf-c-form-control__select--success--PaddingRight: var(--pf-global--spacer--3xl);
	--pf-c-form-control__select--success--BackgroundPosition: calc(
		var(--pf-c-form-control__select--BackgroundPositionX) - var(--pf-global--spacer--lg)
	);
	--pf-c-form-control__select--m-warning--PaddingRight: var(--pf-global--spacer--3xl);
	--pf-c-form-control__select--m-warning--BackgroundPosition: calc(
		var(--pf-c-form-control__select--BackgroundPositionX) - var(--pf-global--spacer--lg) + 0.0625rem
	);
	--pf-c-form-control__select--invalid--PaddingRight: var(--pf-global--spacer--3xl);
	--pf-c-form-control__select--invalid--BackgroundPosition: calc(
		var(--pf-c-form-control__select--BackgroundPositionX) - var(--pf-global--spacer--lg)
	);
	--pf-c-form-control--textarea--Width: var(--pf-c-form-control--Width);
	--pf-c-form-control--textarea--Height: auto;
	--pf-c-form-control--textarea--success--BackgroundPositionY: var(--pf-c-form-control--PaddingLeft);
	--pf-c-form-control--textarea--m-warning--BackgroundPositionY: var(--pf-c-form-control--PaddingLeft);
	--pf-c-form-control--textarea--invalid--BackgroundPositionY: var(--pf-c-form-control--PaddingLeft);
	color: var(--pf-global--Color--100);
	width: var(--pf-c-form-control--Width);
	padding: var(--pf-c-form-control--PaddingTop) var(--pf-c-form-control--PaddingRight)
		var(--pf-c-form-control--PaddingBottom) var(--pf-c-form-control--PaddingLeft);
	font-size: var(--pf-c-form-control--FontSize);
	line-height: var(--pf-c-form-control--LineHeight);
	background-color: var(--pf-c-form-control--BackgroundColor);
	background-repeat: no-repeat;
	border: var(--pf-c-form-control--BorderWidth) solid;
	border-color: none !important;
	border-radius: var(--pf-c-form-control--BorderRadius);
	-moz-appearance: none;
	-webkit-appearance: none;
}
.pf-c-switch__input:checked ~ .pf-c-switch__toggle:before {
	-webkit-transform: translateX(var(--pf-c-switch__input--checked__toggle--before--TranslateX));
	transform: translateX(var(--pf-c-switch__input--checked__toggle--before--TranslateX));
	width: 25px;
	height: 25px;
	margin-left: -15px !important;
	margin-top: -5px !important;
}
.pf-c-switch__toggle:before {
	width: 25px !important;
	height: 25px !important;
	margin-top: -5px !important;
	margin-left: -5px !important;
}
.pf-c-button.pf-m-control {
	--pf-c-button--after--BorderColor: none !important;
}
.ant-menu-item,
.ant-menu-submenu-title {
	position: relative;
	display: block;
	margin: 0;
	padding: 0 20px;
	white-space: nowrap;
	cursor: pointer;
	-webkit-transition: none !important;
	transition: none !important;
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title {
	height: 40px;
	margin-top: 4px;
	margin-bottom: 4px;
	padding: 0 16px;
	overflow: hidden;
	font-size: 20px !important;
	font-weight: 700;
	line-height: 40px;
	text-overflow: ellipsis;
}
.campaign-creator-root .steps-action {
	text-align: center;
	padding: 25px 25% 75px !important;
	border-bottom: 1px solid #333333 !important;
}
.campaign-creator-root .steps-action {
	text-align: center;
	padding: 25px 25% 75px !important;
	border-bottom: 1px solid #333333 !important;
	margin-bottom: 25px !important;
}
.campaign-creator-root .evergreen-videos {
	width: 100% !important;
	padding-left: 0px !important;
	padding-right: 0px !important;
	padding-bottom: 0px !important;
	text-align: center !important;
	margin-left: auto !important;
	margin-right: auto !important;
}
.pf-c-tile.pf-m-selected.pf-m-display-lg {
	border: 5px solid #4fb057 !important;
}
.campaign-creator-root .media-item h3 {
	text-align: center;
	font-size: 25px !important;
	padding: 0 0 10px;
}
h3 {
	font-size: 25px !important;
}
.ant-steps-item-process .ant-steps-item-title {
	font-weight: 700 !important;
}
.campaign-creator-root .ant-modal-footer,
.campaign-creator-root .ant-modal-header {
	border: 0;
	background: transparent;
	color: #fff;
	border-bottom: 1px solid #333333 !important;
}
.campaign-creator-root .wrapper-header {
	display: flex;
	justify-content: space-between;
	padding: 25px 25px 25px !important;
}
.ant-list-item-action {
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	margin-left: 48px;
	padding: 0;
	font-size: 25px !important;
	list-style: none;
	color: rgba(250, 250, 250, 0.8) !important;
	text-align: center !important;
	font-weight: 700 !important;
}
.ant-list-item-meta-title {
	margin-bottom: 4px !important;
	color: #ffffff !important;
	font-size: 20px !important;
	font-weight: 500 !important;
	line-height: 25px !important;
	letter-spacing: 1px !important;
}
.ant-modal {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	color: rgba(0, 0, 0, 0.65);
	font-size: 16px !important;
	font-variant: tabular-nums;
	line-height: 1.5;
	list-style: none;
	-webkit-font-feature-settings: "tnum";
	font-feature-settings: "tnum", "tnum";
	position: relative;
	top: 25px;
	width: auto;
	margin: 0 auto;
	padding: 0 0 24px;
	pointer-events: none;
	height: 100% !important;
	bottom: 0 !important;
	margin-bottom: 0px;
	padding-bottom: 0px !important;
	min-height: 100% !important;
	transform-origin: 530px 566px;
}
.ant-modal-content {
	position: relative;
	background-color: #fff;
	background-clip: padding-box;
	border: 0;
	border-radius: 4px;
	/*-webkit-box-shadow: 0 4px 12px rgba(0 0 0 / 15%);*/
	/*box-shadow: 0 4px 12px rgba(0 0 0 / 15%);*/
	pointer-events: auto;
	height: 100% !important;
}
.campaign-creator-root .ant-modal-body {
	padding: 0;
	height: 100% !important;
}
.ant-modal.campaign-creator-root {
	width: 50% !important;
	border: none !important;
	border-color: transparent !important;
	height: 100% !important;
	border-radius: 25px !important;
}
.campaign-creator-root .campaign-review-cart {
	padding: 40px !important;
}
.campaign-creator-root .ant-modal-content {
	background: rgba(0, 0, 0, 0.8) !important;
	color: #fff;
	height: 100% !important;
	min-height: 1080px !important;
	max-height: 100% !important;
	bottom: 0 !important;
	border-radius: 25px !important;
	bottom: 0 !important;
	border: 1px solid rgba(250, 250, 250, 0.5);
}
.ant-modal-content {
	position: relative;
	background-color: #fff;
	background-clip: padding-box;
	border: 0;
	border-radius: 4px;
	/*-webkit-box-shadow: 0 4px 12px rgba(0 0 0 / 15%);*/
	/*box-shadow: 0 4px 12px rgba(0 0 0 / 15%);*/
	pointer-events: auto;
	height: 100% !important;
}
.success-modal-root .ant-modal-footer {
	padding-bottom: 30px;
	text-align: center !important;
}
.success-modal-root .ant-modal-footer button {
	padding-bottom: 30px;
	text-align: center !important;
	min-width: 300px !important;
}
input[type="checkbox"],
input[type="radio"] {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: 0;
	width: 25px !important;
	height: 25px !important;
}
.space-between-row.blue-back {
	background-color: rgba(0, 136, 230, 0.5);
	padding-bottom: 15px !important;
	border-radius: 10px !important;
}
.space-between-row.blue-back {
	background-color: rgba(0, 136, 230, 0.5);
	padding-bottom: 15px !important;
	border-radius: 10px !important;
	font-size: 25px !important;
}
.ant-card .our-order-card .ant-card-head {
	border-bottom: 0 !important;
	border-color: rgba(250, 250, 250, 0.3) !important;
}
.ant-modal.success-modal-root {
	width: 50% !important;
	background-color: transparent !important;
}
.success-modal-root .ant-modal-content {
	background: rgba(0, 0, 0, 0.8) !important;
	color: #fff;
}
.success-modal-root .ant-modal-content {
	background: rgba(0, 0, 0, 0.8) !important;
	color: #fff;
	border-radius: 25px !important;
	border: 1px solid;
	border-color: rgba(250, 250, 250, 0.3) !important;
	margin-bottom: 25px !important;
}
.ant-modal-header {
	padding: 25px !important;
	color: rgba(0, 0, 0, 0.65);
	border-radius: 0;
	border-bottom: 1px solid rgba(250, 250, 250, 0.3) !important;
}
.success-modal-root .ant-modal-title {
	color: #fff;
	font-size: 35px;
	line-height: 30px;
	font-weight: 400 !important;
}
.success-modal-root .ant-modal-body {
	padding: 30px 30px !important;
	width: 75% !important;
	margin: auto;
}
.ant-modal.success-modal-root {
	width: 50% !important;
	background-color: transparent;
	margin-bottom: 50px !important;
	padding-bottom: 25px !important;
}
.success-modal-root .ant-modal-footer {
	padding: 0 !important;
	/*margin-top: -25px !important;*/
	text-align: center !important;
}
.success-modal-root h2 {
	color: #fff;
	font-size: 30px !important;
	line-height: 30px;
	font-weight: 300 !important;
}
.ant-modal-body p {
	font-size: 25px !important;
	line-height: 1.5;
	word-wrap: break-word;
}
.selectOption{
	border-bottom-color: black !important;
	border-bottom: solid;
	background-color: white !important;
}
.leftImageLabel{
	padding-right:10px !important;
	width: 45% !important;
}
// .selectNone {
// 	font-size: 16px !important;
// 	line-height: 1 !important;
// }

.success-modal-root strong {
	font-size: 25px !important;
	line-height: 30px;
}
.campaign-creator-root .schedule-step .date-picker-cell + .pf-c-switch + p {
	width: 40% !important;
	margin: 0 auto;
	font-size: 16px !important;
	padding-bottom: 60px;
}
.ant-list-item-meta-description {
	color: rgba(0, 0, 0, 0.45);
	font-size: 16px !important;
	line-height: 22px;
}
.campaign-creator-root .ant-modal-content {
	background: rgba(0, 0, 0, 0.8) !important;
	width: 100% !important;
	color: #fff;
	height: 100% !important;
	min-height: 100% !important;
	max-height: 100% !important;
	border-radius: 0;
	bottom: 0 !important;
	border: 0;
	display: inline-table !important;
	border: 1px solid rgba(250, 250, 250, 0.3) !important;
	border-radius: 25px !important;
}
.campaign-creator-root .steps-action button.ant-btn.ant-btn-primary {
	float: right !important;
	width: auto !important;
	padding-left: 25px !important;
	padding-right: 25px !important;
}
.success-modal-root .ant-modal-body {
	padding: 30px 30px !important;
	width: 65% !important;
	margin: auto;
}
.campaign-creator-root .steps-action {
	text-align: center;
	padding: 25px 10% 75px !important;
	border-bottom: 1px solid #333333 !important;
	margin-bottom: 25px !important;
}
.campaign-creator-root .media-item h3 {
	text-align: center;
	font-size: 35px !important;
	padding: 0 0 10px;
}
.ant-checkbox-inner {
	position: relative;
	top: 0;
	left: 0;
	display: block;
	width: 35px;
	height: 35px;
	background-color: #fff;
	border: 5px solid rgba(0, 136, 230, 0.7) !important;
	border-radius: 2px;
	border-collapse: separate;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	border-radius: 50px !important;
}
.ant-list-item-action > li {
	position: relative;
	display: inline-block;
	padding: 0 8px;
	color: rgba(0, 0, 0, 0.45);
	font-size: 25px !important;
	line-height: 35px !important;
	text-align: center;
	font-weight: 300;
	cursor: pointer;
}
.campaign-creator-root .ant-modal-title {
	color: #fff;
	font-size: 35px !important;
	line-height: 30px;
	font-weight: 300 !important;
}
.ant-card-small > .ant-card-body {
	padding: 0px !important;
}
.dashboard-container .promote-btn-wrapper {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding-top: 25px !important;
	margin-top: 0px !important;
	background: transparent !important;
	height: 75px !important;
	/*width: 100%!important;*/
	background-color: transparent !important;
	background-image: -webkit-gradient(
		linear,
		left top,
		left bottom,
		from(transparent),
		to(rgba(0, 0, 0, 0.7))
	) !important;
	background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.7)) !important;
	padding-bottom: 55px !important;
	margin-left: -25px !important;
	margin-right: -25px !important;
	/*margin-bottom: -25px;*/
}
.ant-card-meta-title {
	overflow: hidden;
	color: #ffffff !important;
	font-size: 25px !important;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.dashboard-container .promote-btn-wrapper {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding-top: 25px !important;
	margin-top: 0px !important;
	background: transparent !important;
	height: 75px !important;
	width: 100% !important;
	background-color: transparent !important;
	background-image: -webkit-gradient(
		linear,
		left top,
		left bottom,
		from(transparent),
		to(rgba(0, 0, 0, 0.7))
	) !important;
	background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.7)) !important;
	padding-bottom: 0px !important;
	margin-left: -25px !important;
	margin-right: -25px !important;
	/*margin-bottom: -25px;*/
}
/*.dashboard-container .promote-btn-wrapper {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding-top: 0px !important;
	margin-top: 35px!important;
	background: transparent!important;
	height: 75px!important;
	width: inherit!important;
	background-color: transparent!important;
	background-image: -webkit-gradient(linear,left top,left bottom,from(transparent),to(rgba(0,0,0,.7)))!important;
	background-image: linear-gradient(transparent,rgba(0,0,0,.7))!important;
	padding-bottom: 0px!important;
	margin-left: -25px !important;
	margin-right: -25px !important;
  }*/
.dashboard-container .promote-btn-wrapper button.ant-btn.ant-btn-primary.ant-btn-round.ant-btn-sm {
	background-color: #4fb057 !important;
	border-color: #4fb057 !important;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: auto;
	margin-top: 0 !important;
}
/*.dashboard-container .promote-btn-wrapper {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding-top: 0px !important;
	margin-top: 0px!important;
	background: transparent!important;
	height: 75px!important;
	margin-top: -25px !important;
	width: inherit!important;
	background-color: transparent!important;
	background-image: -webkit-gradient(linear,left top,left bottom,from(transparent),to(rgba(0,0,0,.7)))!important;
	background-image: linear-gradient(transparent,rgba(0,0,0,.7))!important;
	padding-bottom: 0px!important;
	margin-left: -25px !important;
	margin-right: -25px !important;
  }*/
ul.ant-menu.ant-menu-dark.ant-menu-root.ant-menu-vertical {
	display: inline-table !important;
	height: 89% !important;
	border-bottom-left-radius: 25px !important;
}
.dashboard-container .your-inventory-box .inventory-scroll-box {
	/*height: 385px !important;*/
}
.dashboard-container .your-inventory-box .your-inventory-filter {
	display: flex;
	justify-content: space-between;
	padding-bottom: 15px !important;
	padding-top: 15px !important;
	border-bottom: 1px solid;
	border-color: rgba(250, 250, 250, 0.2) !important;
}
.ant-col.border-top.auto-height.border-left.border-right.ant-col-md-24.ant-col-lg-24.ant-col-xl-12.ant-col-xxl-12 {
	height: 585px !important;
}
.ant-col.auto-height.border-top.ant-col-md-24.ant-col-lg-24.ant-col-xl-6.ant-col-xxl-6 {
	height: 585px !important;
}
/* Inventory List - Details Text */
.dashboard-container .car-list-extra {
	color: #fff;
	font-size: 1.3rem !important;
	line-height: 1.9rem !important;
	margin-top: -5px !important;
}
.dashboard-container .car-list-extra strong {
	font-weight: 700;
	font-size: 18px !important;
	line-height: 1.9rem;
}
/**/
.dashboard-container .your-order-card .ant-card-body {
	height: 90% !important;
	padding: 0;
	margin-top: 0px !important;
}
.dashboard-container .top-ads-box .dynamic-video-scroll-box,
.dashboard-container .your-inventory-box .dynamic-video-scroll-box {
	height: 410px !important;
}
ul.ant-menu.ant-menu-dark.ant-menu-inline-collapsed.ant-menu-root.ant-menu-vertical {
	border-bottom-left-radius: 0px !important;
}
.ant-carousel .slick-dots {
	position: absolute;
	display: block;
	width: 100%;
	height: 10px !important;
	margin: 0;
	padding: 10px !important;
	text-align: center;
	list-style: none;
}
.ant-carousel .slick-dots li {
	position: relative;
	display: inline-block;
	margin: 0 2px;
	padding: 10px !important;
	text-align: center;
	vertical-align: top;
}
.ant-carousel .slick-dots li button {
	display: block;
	width: 16px;
	height: 10px !important;
	padding: 0;
	border-radius: 25px !important;
	color: transparent;
	font-size: 0;
	background: #fff;
	border: 0;
	outline: none;
	cursor: pointer;
	opacity: 0.3;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
}
.campaign-creator-root .wrapper-header-details {
	color: #fff;
	font-size: 14px !important;
	padding-bottom: 8px;
	display: flex;
	justify-content: space-between;
	width: 100% !important;
}
.campaign-creator-root .wrapper-header-details .wrapper-header-details-totals {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 25% !important;
	text-align: center;
	border-left: 1px solid rgba(250, 250, 250, 0.5) !important;
}
.campaign-creator-root .wrapper-header-details .wrapper-header-details-totals span:last-child {
	font-size: 25px !important;
	margin-top: 8px;
}
.campaign-creator-root .wrapper-header-details {
	color: #fff;
	font-size: 16px !important;
	padding-bottom: 8px;
	display: flex;
	justify-content: space-between;
	width: 100% !important;
}
.ant-avatar {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5;
	list-style: none;
	-webkit-font-feature-settings: "tnum";
	font-feature-settings: "tnum", "tnum";
	position: relative;
	display: inline-block;
	overflow: hidden;
	color: #fff;
	white-space: nowrap;
	text-align: center;
	vertical-align: middle;
	background: #ccc;
	width: 32px;
	height: 32px;
	line-height: 32px;
	border-radius: 50%;
}
.ant-btn {
	/*background-color: rgba(250, 250, 250, .6) !important;*/
	border: 1px solid rgba(250, 250, 250, 0.7) !important;
}
.ant-btn {
	color: #777777;
	/*border: 1px solid hsla(0,0%,98%,.7)!important;*/
}
.tab-card .ant-tabs-tab-active {
	background: rgba(0, 136, 230, 0.9) !important;
	font-weight: 400;
	border: 1px solid !important;
	border-color: rgba(250, 250, 250, 0.3) !important;
}
/*WW 5/3 */
.tab-card .ant-tabs-tab {
	font-size: 1rem !important;
	font-weight: 700 !important;
	color: #fff !important;
	border: 0;
	padding: 10px 15px !important;
	border-radius: 50px !important;
	background: rgba(250, 250, 250, 0.2) !important;
	margin-right: 10px;
	min-width: 75px !important;
	border: 1px solid !important;
	border-color: rgba(250, 250, 250, 0.2) !important;
}
.dashboard-container .social-media-list .space-between-row .social-data {
	font-size: 14px !important;
	line-height: 1rem;
	margin-top: 2px;
	margin-right: 10px;
}
.dashboard-container .social-media-list .space-between-row .ant-card-meta-detail .ant-card-meta-description {
	color: #fff;
	margin-left: 2px;
	font-size: 14px !important;
}
.dashboard-container .dynamic-video-row .video-data-text {
	font-size: 0.8rem !important;
}
.tab-card .ant-tabs-tab {
	font-size: 1.1rem !important;
	font-weight: 700 !important;
	color: #fff !important;
	border: 0;
	padding: 10px 15px !important;
	border-radius: 50px !important;
	background: rgba(250, 250, 250, 0.2);
	margin-right: 10px;
	min-width: 75px !important;
	border: 1px solid !important;
	border-color: rgba(250, 250, 250, 0.2) !important;
}
.space-between-row.blue-back {
	background-color: rgba(0, 136, 230, 0.5);
	padding-bottom: 15px !important;
	border-radius: 10px !important;
	font-size: 20px !important;
}
.ant-btn-round.ant-btn-sm {
	height: 45px !important;
	padding: 0px 18px !important;
	font-size: 20px !important;
	border-radius: 50px !important;
}
.ant-col.border-top.auto-height.border-left.border-right.ant-col-md-24.ant-col-lg-24.ant-col-xl-12.ant-col-xxl-12 {
	height: 585px !important;
}
.ant-col.auto-height.border-top.ant-col-md-24.ant-col-lg-24.ant-col-xl-6.ant-col-xxl-6 {
	height: 585px !important;
}
.tab-card .ant-card-meta-title {
	color: #fff;
	margin-top: 5px;
	margin-bottom: 10px !important;
	font-size: 25px !important;
	font-weight: 700;
	padding-bottom: 5px !important;
}
.live-monitor-filter > span {
	font-weight: 400;
}
.ant-select {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: #fff !important;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5;
	font-weight: 400 !important;
	list-style: none;
	font-feature-settings: "tnum", "tnum", "tnum";
	position: relative;
	display: inline-block;
	outline: 0;
}
.ant-list-item-meta-title {
	margin-bottom: 4px !important;
	color: #ffffff !important;
	font-size: 20px !important;
	font-weight: 700 !important;
	line-height: 25px !important;
	letter-spacing: 1px !important;
}
.dashboard-container .car-list-extra {
	color: #fff;
	font-size: 18px !important;
	line-height: 1.9rem !important;
	margin-top: -5px !important;
}
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
	border-color: #1890ff;
}
.ant-checkbox-inner {
	position: relative;
	top: 0;
	left: 0;
	display: block;
	width: 25px;
	height: 25px;
	background-color: #fff;
	border: 3px solid rgba(0, 136, 230, 0.7) !important;
	border-radius: 2px;
	border-collapse: separate;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	border-radius: 50px !important;
}
.ant-checkbox-checked .ant-checkbox-inner:after {
	position: absolute;
	display: table;
	border: 2px solid #fff;
	border-top: 0;
	border-left: 0;
	-webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
	-ms-transform: rotate(45deg) scale(1) translate(-50%, -50%);
	transform: rotate(45deg) scale(1) translate(-50%, -50%);
	opacity: 1;
	-webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
	transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
	content: " ";
	width: 10px;
	height: 20px;
}
.pf-c-switch {
	font-size: 16px !important;
}
.campaign-creator-root .wrapper-header-details .wrapper-header-details-totals span:last-child {
	font-size: 20px !important;
	font-weight: 700 !important;
}
.campaign-creator-root .media-item h3,
h3 {
	font-size: 25px !important;
	font-weight: 700 !important;
}
.ant-modal-body {
	font-size: 16px !important;
}
.ant-list-item-action > li {
	position: relative;
	display: inline-block;
	padding: 0 8px;
	color: rgba(0, 0, 0, 0.45);
	font-size: 20px !important;
	line-height: 35px !important;
	text-align: center;
	font-weight: 700 !important;
	cursor: pointer;
}
/* TEST */
[class*="pf-c-"],
[class*="pf-c-"]:after,
[class*="pf-c-"]:before {
	/*border-radius: 50px !important;*/
	border: none !important;
}
.pf-c-input-group:disabled {
	--pf-global--Color--100: var(--pf-global--Color--dark-100);
	--pf-global--Color--200: var(--pf-global--Color--dark-200);
	--pf-global--BorderColor--100: var(--pf-global--BorderColor--dark-100);
	--pf-global--primary-color--100: var(--pf-global--primary-color--dark-100);
	--pf-global--link--Color: var(--pf-global--link--Color--dark);
	--pf-global--link--Color--hover: var(--pf-global--link--Color--dark--hover);
	/*--pf-global--BackgroundColor--100: rgba(250, 250, 250, 1.0) !important;*/
	--pf-c-input-group--BackgroundColor: var(--pf-global--BackgroundColor--100);
	--pf-c-input-group--child--ZIndex: var(--pf-global--ZIndex--xs);
	--pf-c-input-group__text--FontSize: var(--pf-global--FontSize--md);
	--pf-c-input-group__text--PaddingRight: var(--pf-global--spacer--sm);
	--pf-c-input-group__text--PaddingLeft: var(--pf-global--spacer--sm);
	--pf-c-input-group__text--Color: var(--pf-global--Color--dark-200);
	--pf-c-input-group__text--BorderWidth: var(--pf-global--BorderWidth--sm);
	--pf-c-input-group__text--BorderTopColor: var(--pf-global--BorderColor--300);
	--pf-c-input-group__text--BorderRightColor: var(--pf-global--BorderColor--300);
	--pf-c-input-group__text--BorderBottomColor: var(--pf-global--BorderColor--200);
	--pf-c-input-group__text--BorderLeftColor: var(--pf-global--BorderColor--300);
	--pf-c-input-group__text--BackgroundColor: var(--pf-global--BackgroundColor--100);
	--pf-c-input-group__textarea--MinHeight: var(--pf-global--spacer--xl);
	--pf-c-input-group--c-form-control--invalid--ZIndex: var(--pf-global--ZIndex--xs);
	--pf-c-input-group--c-form-control--MarginRight: 0;
	color: var(--pf-global--Color--100);
	display: flex;
	width: 100%;
	background-color: var(--pf-c-input-group--BackgroundColor);
}
.pf-c-form-control:disabled {
	--pf-c-form-control--Color: var(--pf-c-form-control--disabled--Color);
	/*--pf-c-form-control--BackgroundColor: rgba(250, 250, 250, .0) !important;*/
	cursor: not-allowed;
	border-color: var(--pf-c-form-control--disabled--BorderColor);
}
.pf-c-button.pf-m-aria-disabled,
.pf-c-button.pf-m-disabled,
.pf-c-button:disabled {
	color: var(--pf-c-button--disabled--Color);
}
.pf-c-radio__input:last-child {
	margin-right: var(--pf-c-radio__input--last-child--MarginRight);
	margin-right: 10px !important;
	margin-bottom: 5px !important;
}
.campaign-creator-root .evergreen-videos h3 {
	font-size: 20px !important;
	font-weight: 700 !important;
	padding: 0 0 10px;
}
.campaign-creator-root .evergreen-videos {
	width: 100% !important;
	padding-left: 0px !important;
	padding-right: 0px !important;
	padding-bottom: 25px !important;
	text-align: center !important;
	margin-left: auto !important;
	margin-right: auto !important;
}
.success-modal-root .ant-modal-footer button {
	padding-bottom: inherit !important;
	text-align: center !important;
	min-width: 300px !important;
}
/* LOGIN */
.pf-c-login__main {
	margin-bottom: var(--pf-c-login__main--MarginBottom);
	background-color: var(--pf-c-login__main--BackgroundColor);
	grid-area: main;
	background-color: rgba(250, 250, 250, 0.1) !important;
	border: 1px solid !important;
	border-color: rgba(250, 250, 250, 0.2) !important;
	border-radius: 25px !important;
	color: #ffffff !important;
}
h2.pf-c-title.pf-m-3xl {
	color: #ffffff !important;
}
.pf-c-login__main-footer-band {
	padding: var(--pf-c-login__main-footer-band--PaddingTop) var(--pf-c-login__main-footer-band--PaddingRight)
		var(--pf-c-login__main-footer-band--PaddingBottom) var(--pf-c-login__main-footer-band--PaddingLeft);
	text-align: center;
	background-color: transparent !important;
}
.login-page a {
	color: #ffffff !important;
}
.login-page a:hover {
	color: #06c !important;
	text-decoration: none !important;
}
.pf-c-form__label {
	font-size: 16px !important;
	line-height: var(--pf-c-form__label--LineHeight);
	font-weight: 700 !important;
}
.pf-c-button:after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	pointer-events: none;
	content: "";
	border: var(--pf-c-button--after--BorderWidth) solid;
	border-color: var(--pf-c-button--after--BorderColor);
	border-radius: 50px !important;
}
button.pf-c-button.pf-m-primary.pf-m-block {
	display: block;
	border: 1px solid !important;
	border-color: rgba(250, 250, 250, 0.2) !important;
	border-radius: 55px !important;
	width: 100% !important;
	height: 45px !important;
	border-radius: 50px !important;
	font-size: 20px !important;
	font-weight: 700 !important;
}
.pf-c-login__main-header {
	text-align: center !important;
}
.pf-c-login__main-header-desc {
	font-size: 16px !important;
}
.login-autocomplete.css-2b097c-container {
	color: #777777 !important;
}
/* END LOGIN */
/* HEADER BAR - PARTNER DROPDOWN */
.app-layout header .partner-autocomplete > span + div,
.app-layout header .partner-autocomplete > span + div * {
	background: transparent;
	color: #fff;
	line-height: 14px;
	border-radius: 50px !important;
	border-color: rgba(250, 250, 250, 0.4) !important;
}
/* BUTTON Disabled */
button.ant-btn.ant-btn-primary.ant-btn-round.ant-btn-sm:disabled {
	opacity: 0.5 !important;
}
.dashboard-container .promote-btn-wrapper {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding-top: 0 !important;
	/*background: transparent !important;*/
	background-color: transparent !important;
	height: 85px !important;
	width: inherit !important;
	background-color: transparent !important;
	/*background-image: -webkit-gradient(linear,left top,left bottom,from(transparent),to(rgba(0,0,0,.7))) !important;*/
	/*background-image: linear-gradient(rgba(0,0,0,.0),rgba(0,0,0,.8)) !important;*/
	padding-bottom: 0 !important;
	margin: -85px -25px -50px !important;
	z-index: 9000 !important;
}
/* FEEDBACK MODAL */
.frf-modal-button {
	padding: 15px 30px !important;
	outline: 0;
	border-radius: 4px;
	border: 1px solid;
	border-color: rgba(110, 120, 152, 0.15);
	margin-top: 16px;
	cursor: pointer;
	width: 100%;
	font-weight: 500 !important;
	border-radius: 50px !important;
	background-color: rgba(0, 136, 230, 0.9) !important;
}
.frf-modal-container {
	display: flex;
	flex-direction: column;
	text-align: left;
	position: absolute;
	right: 12px;
	bottom: 108px;
	background: rgba(250, 250, 250, 0.8) !important;
	/*box-shadow: 0 20px 40px rgb(0 0 0 / 10%);*/
	max-width: 380px !important;
	width: 80% !important;
	max-height: 70vh;
	border: 1px solid !important;
	border-color: rgba(250, 250, 250, 0.5) !important;
	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow: scroll;
	border-radius: 15px !important;
	font-size: 16px !important;
}
#feedbackEmail {
	color: #151515;
	border-radius: 50px !important;
}
#feedbackMsg {
	color: #151515;
	border-radius: 10px !important;
}
.frf-modal-feedback-types .frf-modal-feedback-type:first-of-type {
	border-radius: 50px !important;
}
.frf-modal-label {
	color: rgba(0, 0, 0, 0.9) !important;
	padding-bottom: 8px;
	font-size: 14px;
	display: inline-block;
	font-weight: 500;
}
.frf-modal-feedback-types .frf-modal-feedback-type {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 500 !important;
	overflow-wrap: anywhere;
	max-width: 100/3;
	padding: 12px;
	text-align: center;
	border: 1px solid rgba(110, 120, 152, 0.15) !important;
	cursor: pointer;
	border-radius: 50px !important;
}
.app-layout header .dealer-info {
	width: 89% !important;
}
.ant-checkbox-inner {
	width: 35px !important;
	height: 35px !important;
	border: 4px solid rgba(0, 0, 0, 0.5) !important;
}
div.cart-row .ant-card-meta-description {
	color: #fff;
	font-size: 16px !important;
}
/* CLOSE MODAL BUTTON */
.frf-trigger-button {
	justify-content: space-between;
	position: absolute;
	right: 12px;
	bottom: 24px;
	white-space: nowrap;
	cursor: pointer;
	/*box-shadow: 0 20px 40px rgb(0 0 0 / 10%);*/
	padding: 15px 15px !important;
	outline: 0;
	border-radius: 4px;
	border: none;
	border-radius: 50px !important;
	background-color: rgba(250, 250, 250, 0.3) !important;
}
/* DOUGHNUT CHART */
text#legend-labels-0 > tspan,
text#legend-labels-1 > tspan,
text#legend-labels-2 > tspan,
text#legend-labels-3 > tspan,
text#legend-labels-4 > tspan,
text#legend-labels-5 > tspan,
text#legend-labels-6 > tspan,
text#legend-labels-7 > tspan {
	font-size: 10px !important;
}
text#legend-labels-1 > tspan {
	font-size: 10px !important;
}
text#legend-labels-2 > tspan {
	font-size: 10px !important;
}
.tab-card .ant-tabs-top-bar {
	border: 0;
	padding-bottom: 25px;
	margin-bottom: 0;
	border-bottom: none !important;
	border-color: rgba(250, 250, 250, 0.2) !important;
}
.campaign-creator-root .package-channels {
	display: none !important;
}
/*BUTTON*/
.ant-btn {
	background-color: transparent !important;
	border: 1px solid #d9d9d9;
}
.ant-btn-primary {
	border-color: #00a6e6 !important;
	background: #00a6e6 !important;
	color: #fff !important;
	border-radius: 50px !important;
	min-width: 150px !important;
	font-size: 20px !important;
	padding: 0;
	height: 45px !important;
}
.campaign-creator-root .steps-action button.ant-btn.ant-btn-primary {
	float: right !important;
	width: auto !important;
	padding-left: 25px !important;
	padding-right: 25px !important;
}
.ant-modal-close {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 10;
	padding: 0;
	color: rgba(0, 0, 0, 0.45);
	font-weight: 700;
	line-height: 1;
	text-decoration: none;
	background: rgba(250, 250, 250, 0.2) !important;
	border: 0;
	border-radius: 50px !important;
	outline: 0;
	padding: 0px !important;
	height: 45px !important;
	width: 45px !important;
	align-content: center;
	align-items: center;
	cursor: pointer;
	-webkit-transition: color 0.3s;
	transition: color 0.3s;
	margin-top: 15px !important;
	margin-right: 15px;
}
.ant-modal-close .anticon {
	color: #fff !important;
	vertical-align: 0.125em !important;
	padding-right: 10px !important;
}
.tab-card .ant-card-meta-title {
	color: #fff;
	margin-top: 5px;
	margin-bottom: 10px !important;
	font-size: 25px !important;
	font-weight: 700;
	padding-bottom: 0px !important;
}
/* SEARCH */
.ant-input {
	height: 45px !important;
}
/* NEW FILTER LAYOUT */
/* TITLES */
/*.dashboard-container .live-monitoring-text {
	background: transparent !important;
	font-size: 1.5rem;
	height: 55px;
	align-items: center;
	justify-content: center;
	font-weight: 700;
	text-align: left;
	float: left;
	padding-left: 10px !important;
  }*/
/* CHECKBOX */
.ant-checkbox-inner {
	width: 35px !important;
	height: 35px !important;
	border: 4px solid #4fb057 !important;
}
.ant-card.pie-chart-card .ant-card-meta-title {
	color: #fff;
	margin-top: 5px;
	margin-bottom: 10px !important;
	font-size: 1.5rem !important;
	font-weight: 700;
	text-align: left !important;
}
/* TITLES*/
.campaign-creator-root .media-item h3,
h3 {
	font-size: 20px !important;
	font-weight: 700 !important;
}
.campaign-creator-root .ant-modal-title {
	color: #fff;
	font-size: 25px !important;
	line-height: 30px;
	font-weight: 300 !important;
}
.ant-card-meta-title {
	color: #ffffff !important;
	font-size: 20px !important;
	font-weight: 700 !important;
}
/* FUTURE TITLE */
.dashboard-container .your-inventory-title,
.dashboard-container .top-performing-title {
	background: transparent !important;
	font-size: 1.5rem;
	height: 75px;
	align-items: center;
	color: #fff;
	display: flex;
	padding-left: 10px;
	margin: 0;
	justify-content: normal;
	text-align: left !important;
	font-weight: 700 !important;
	float: left !important;
}
.dashboard-container .top-ads-box .top-ads-filter,
.dashboard-container .your-inventory-box .top-ads-filter {
	text-align: left;
	padding-bottom: 5px;
	border-bottom: 0 !important;
	border-color: hsla(0, 0%, 98%, 0.2) !important;
	padding-bottom: 25px !important;
	float: right !important;
}
.dashboard-container .top-ads-box .dynamic-video-scroll-box,
.dashboard-container .your-inventory-box .dynamic-video-scroll-box {
	height: 410px !important;
	float: left !important;
}
.app-layout header > div > .anticon {
	margin: 0 15px;
	float: right !important;
}
.dashboard-container .your-inventory-box .inventory-scroll-box {
	/*height: 455px !important;*/
}
.campaign-review-cart .signature-pad canvas,
.dashboard-container .signature-pad canvas {
	margin: 0 auto;
	background: rgba(0, 0, 0, 0.7) !important;
	border: 1px solid;
	border-color: hsla(0, 0%, 98%, 0.5) !important;
	width: 100% !important;
}
.centered-row {
	display: flex;
	justify-content: center;
	padding: 5px 10px;
	z-index: 20000;
	margin-top: -25px !important;
}
.dashboard-container .your-order-card .card-scroll-content {
	height: 60% !important;
	font-size: 0.8rem;
}
.centered-row {
	display: flex;
	justify-content: center;
	padding: 5px 10px;
	z-index: 20000;
	margin-top: -65px !important;
}
.app-layout header .dealer-info {
	width: 65% !important;
}
.app-layout header > div > .anticon {
	margin: 0 25px;
	float: right !important;
	margin-top: 10px !important;
}
.frf-modal-feedback-types .frf-modal-feedback-type {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 500 !important;
	overflow-wrap: anywhere;
	max-width: 100/3;
	padding: 5px !important;
	text-align: center;
	border: 1px solid rgba(110, 120, 152, 0.15) !important;
	cursor: pointer;
	margin-right: 5px !important;
	margin-left: 5px !important;
	border-radius: 50px !important;
}
.dashboard-container .live-monitoring-text {
	background: transparent !important;
	font-size: 25px !important;
	height: 55px;
	align-items: center;
	justify-content: center;
	font-weight: 700 !important;
	float: left;
}
.dashboard-container .inventory-pie-chart .card-scroll-content {
	height: 225px !important;
	padding-top: 0px !important;
}
.campaign-review-cart .signature-pad canvas,
.dashboard-container .signature-pad canvas {
	margin: 0 auto;
	background: rgba(0, 0, 0, 0.7) !important;
	border: 1px solid;
	border-color: hsla(0, 0%, 98%, 0.5) !important;
	width: 100% !important;
	border-radius: 15px !important;
}
.dashboard-container .chart-top-text {
	background: transparent !important;
	font-size: 25px !important;
	height: 55px;
	padding: 0 10px;
	font-weight: 700 !important;
}
.dashboard-container .live-monitoring-text {
	background: transparent !important;
	font-size: 25px !important;
	height: 55px;
	align-items: center;
	justify-content: center;
	font-weight: 700 !important;
}
.dashboard-container .your-inventory-title,
.dashboard-container .top-performing-title {
	background: transparent !important;
	font-size: 25px !important;
	text-align: left !important;
	font-weight: 700 !important;
}
.dashboard-container .dynamic-video-row .video-data-text {
	font-size: 16px !important;
}
.live-monitor-filter > span {
	font-weight: 400 !important;
	font-size: 14px !important;
}
.your-order-card .card-scroll-content {
	font-size: 14px !important;
	font-weight: 400 !important;
}
/* Date DropDown*/
/*
  .ant-calendar {
	position: relative;
	width: 280px;
	font-size: 14px;
	line-height: 1.5;
	text-align: left;
	list-style: none;
	background-color: rgba(0,0,0,.9);
	background-clip: padding-box;
	border: 1px solid #fff;
	border-radius: 4px;
	outline: none;
	color: #ffffff !important;
  }
  
  
  .ant-calendar-range .ant-calendar-input, .ant-calendar-range .ant-calendar-time-picker-input {
	position: relative;
	display: inline-block;
	width: 100%;
	height: 32px;
	color: #ffffff !important;
	font-size: 14px;
	line-height: 1.5;
	background-color: #fff;
	background-image: none;
	border-radius: 4px;
	-webkit-transition: all .3s;
	transition: all .3s;
	height: 24px;
	padding: 4px 0;
	line-height: 24px;
	border: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
  }
  
  .ant-calendar-header .ant-calendar-next-century-btn, .ant-calendar-header .ant-calendar-next-decade-btn, .ant-calendar-header .ant-calendar-next-month-btn, .ant-calendar-header .ant-calendar-next-year-btn, .ant-calendar-header .ant-calendar-prev-century-btn, .ant-calendar-header .ant-calendar-prev-decade-btn, .ant-calendar-header .ant-calendar-prev-month-btn, .ant-calendar-header .ant-calendar-prev-year-btn {
	position: absolute;
	top: 0;
	display: inline-block;
	padding: 0 5px;
	color: #ffffff !important;
	font-size: 16px;
	font-family: Arial,"Hiragino Sans GB","Microsoft Yahei","Microsoft Sans Serif",sans-serif;
	line-height: 40px;
  }
  
  .ant-calendar-header .ant-calendar-century-select, .ant-calendar-header .ant-calendar-decade-select, .ant-calendar-header .ant-calendar-month-select, .ant-calendar-header .ant-calendar-year-select {
	display: inline-block;
	padding: 0 2px;
	color: #ffffff !important;
	font-weight: 500;
	line-height: 40px;
  }
  
  .ant-calendar-last-month-cell .ant-calendar-date, .ant-calendar-last-month-cell .ant-calendar-date:hover, .ant-calendar-next-month-btn-day .ant-calendar-date, .ant-calendar-next-month-btn-day .ant-calendar-date:hover {
	color: #ffffff !important;
	background: transparent;
	border-color: transparent;
  }
  
  .ant-calendar-date {
	display: block;
	width: 24px;
	height: 24px;
	margin: 0 auto;
	padding: 0;
	color: #ffffff !important;
	line-height: 22px;
	text-align: center;
	background: transparent;
	border: 1px solid transparent;
	border-radius: 2px;
	-webkit-transition: background .3s ease;
	transition: background .3s ease;
  }
  
  .ant-calendar-date:hover {
	display: block;
	width: 24px;
	height: 24px;
	margin: 0 auto;
	padding: 0;
	color: #777777 !important;
	line-height: 22px;
	text-align: center;
	background: transparent;
	border: 1px solid transparent;
	border-radius: 2px;
	-webkit-transition: background .3s ease;
	transition: background .3s ease;
  }
  
  .ant-calendar-picker-small .ant-calendar-picker-clear, .ant-calendar-picker-small .ant-calendar-picker-icon {
	right: 15px !important;
  }
  
  */
.ant-checkbox-inner {
	width: 35px !important;
	height: 35px !important;
	border: 5px solid #4fb057 !important;
}
.dashboard-container .live-monitoring-text {
	background: transparent !important;
	font-size: 25px !important;
	height: 55px;
	align-items: center;
	justify-content: center;
	font-weight: 700 !important;
	float: left;
	padding-left: 15px !important;
}
.app-layout header .partner-autocomplete {
	margin-left: 10px;
	margin-right: 30px;
	width: 275px !important;
}
.css-tlfecz-indicatorContainer,
.css-tlfecz-indicatorContainer:active,
.css-tlfecz-indicatorContainer:enabled {
	/*padding: 6px !important;*/
}
.app-layout header .partner-autocomplete > span + div,
.app-layout header .partner-autocomplete > span + div * {
	background: transparent;
	color: #fff;
	line-height: 30px !important;
	border-radius: 50px !important;
	border-color: rgba(250, 250, 250, 0.4) !important;
	width: auto !important;
	padding-right: 2px !important;
}
.app-layout header .partner-autocomplete > span + div,
.app-layout header .partner-autocomplete > span + div *:active {
	background: transparent;
	color: #fff;
	line-height: 30px !important;
	border-radius: 50px !important;
	border-color: rgba(250, 250, 250, 0.4) !important;
	width: auto !important;
}
.dashboard-container .your-inventory-box .your-inventory-filter {
	display: flex;
	justify-content: space-between;
	padding-bottom: 14px !important;
	padding-top: 15px !important;
	border-bottom: 1px solid;
	border-color: rgba(250, 250, 250, 0.2) !important;
}
.ant-tabs {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5;
	list-style: none;
	-webkit-font-feature-settings: "tnum";
	font-feature-settings: "tnum", "tnum";
	position: relative;
	overflow: hidden;
	zoom: 1;
	width: 100% !important;
}
.dashboard-container .top-ads-box .top-ads-filter,
.dashboard-container .your-inventory-box .top-ads-filter {
	text-align: left;
	padding-bottom: 5px;
	border-bottom: 0 !important;
	/*border-color: hsla(0,0%,98%,.2) !important;*/
	padding-bottom: 25px !important;
	float: right !important;
	padding-top: 10px !important;
}

/*PERFOMANCE UPDATES*/
.dashboard-container .your-inventory-title,
.dashboard-container .top-performing-title {
	background: transparent !important;
	font-size: 25px !important;
	text-align: left !important;
	font-weight: 700 !important;
	width: 40%;
}
h3.top-performing-title {
	width: 55% !important;
}
.dashboard-container .your-inventory-box .your-inventory-filter {
	display: flex;
	justify-content: space-between;
	padding-bottom: 14px !important;
	padding-top: 15px !important;
	border-bottom: 1px solid;
	border-color: rgba(250, 250, 250, 0.2) !important;
	width: 70% !important;
	float: right !important;
}
.dashboard-container .your-inventory-title,
.dashboard-container .top-performing-title {
	background: transparent !important;
	font-size: 25px !important;
	text-align: left !important;
	font-weight: 700 !important;
	width: 30%;
}
.top-ads-filter {
	width: 35% !important;
}
/* UPDATED MAY 13 */
.ant-col.border-bottom.ant-col-md-12.ant-col-lg-12.ant-col-xl-6.ant-col-xxl-6 {
	width: 25% !important;
}
.ant-col.border-bottom.border-left.border-right.ant-col-md-12.ant-col-lg-12.ant-col-xl-12.ant-col-xxl-12 {
	width: 45% !important;
}
.ant-col.auto-height.border-top.ant-col-md-24.ant-col-lg-24.ant-col-xl-6.ant-col-xxl-6 {
	width: 25% !important;
}
.ant-col.auto-height.border-top.ant-col-md-24.ant-col-lg-24.ant-col-xl-6.ant-col-xxl-6 {
	width: 25% !important;
}
.ant-col.border-top.auto-height.border-left.border-right.ant-col-md-24.ant-col-lg-24.ant-col-xl-12.ant-col-xxl-12 {
	width: 45% !important;
}
.dashboard-container {
	margin: 0;
	padding: 0px;
	width: 100% !important;
	margin-left: auto;
	margin-right: auto;
}
.dark-back,
.light-back {
	background: rgba(0, 0, 0, 0.5) !important;
}
.ant-row {
	position: relative;
	height: auto;
	margin-right: 0;
	margin-left: 0;
	zoom: 1;
	display: block;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding-left: 15px !important;
	padding-top: 15px !important;
	padding-bottom: 15px !important;
}
.dashboard-container .dashboard-row > div {
	height: 550px;
	/* width: 25%; */
	overflow: hidden;
	padding: 15px;
	background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)) !important;
	border-radius: 25px !important;
	border: 1px solid;
	border-color: rgba(250, 250, 250, 0.3) !important;
	margin: 10px;
}
.dashboard-container .dashboard-row > div {
	height: 550px;
	/* width: 25%; */
	overflow: hidden;
	padding: 15px;
	background-image: linear-gradient(rgba(250, 250, 250, 0.1), rgba(0, 0, 0, 0.7)) !important;
	border-radius: 25px !important;
	border: 1px solid;
	border-color: rgba(250, 250, 250, 0) !important;
	margin: 10px;
}
.ant-card-head {
	min-height: 48px;
	margin-bottom: -1px;
	padding: 0 24px;
	color: rgba(0, 0, 0, 0.85);
	font-weight: 500;
	font-size: 16px;
	background: transparent;
	border-bottom: 1px solid;
	border-color: rgba(250, 250, 250, 0) !important;
	border-radius: 2px 2px 0 0;
	zoom: 1;
}
/* UPDATE */
.top-ads-filter {
	width: 45% !important;
}
.ant-calendar-picker-small .ant-calendar-picker-clear,
.ant-calendar-picker-small .ant-calendar-picker-icon {
	right: 15px !important;
}
.dashboard-container .dashboard-row > div {
	height: 550px;
	/* width: 25%; */
	overflow: hidden;
	padding: 15px;
	background-image: linear-gradient(rgba(250, 250, 250, 0.1), rgba(0, 0, 0, 0.7)) !important;
	border-radius: 25px !important;
	border: 0px solid !important;
	border-color: rgba(250, 250, 250, 0) !important;
	margin: 10px;
}
.dark-back,
.light-back {
	background: rgba(0, 0, 0, 0) !important;
}
.app-layout .ant-layout {
	background: transparent !important;
	background-color: transparent !important;
}
.app-layout {
	min-height: 100vh;
	background: rgba(0, 0, 0, 0.7) !important;
}
.top-ads-filter {
	width: 37% !important;
}
.ant-tabs {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5;
	list-style: none;
	-webkit-font-feature-settings: "tnum";
	font-feature-settings: "tnum", "tnum";
	position: relative;
	overflow: hidden;
	zoom: 1;
	width: 100% !important;
	border-top: 1px solid !important;
	border-color: rgba(250, 250, 250, 0.3) !important;
	margin-top: 15px !important;
}
.dashboard-container .social-media-list {
	height: 400px;
	background: transparent !important;
	margin: 0px !important;
	padding: 0px !important;
	margin-top: 15px !important;
}
.dashboard-container .optimizations-card .ant-tabs-top-bar {
	padding: 25px 2px 15px;
}
.dashboard-container .top-ads-box .dynamic-video-scroll-box,
.dashboard-container .your-inventory-box .dynamic-video-scroll-box {
	height: 410px !important;
	float: left !important;
	border-top: 1px solid;
	border-color: rgba(250, 250, 250, 0.3) !important;
}
.dashboard-container .top-ads-box .top-ads-filter,
.dashboard-container .your-inventory-box .top-ads-filter {
	text-align: left;
	padding-bottom: 5px;
	border-bottom: 0 !important;
	/* border-color: hsla(0,0%,98%,.2) !important; */
	padding-bottom: 15px !important;
	float: right !important;
	padding-top: 10px !important;
}
.dynamic-video-scroll-box,
.dashboard-container .your-inventory-box .dynamic-video-scroll-box {
	height: 475px !important;
	float: left !important;
	border-top: 1px solid;
	border-color: rgba(250, 250, 250, 0.3) !important;
}
body,
html {
	width: 100%;
	// height: auto !important;
}
h3.your-inventory-title {
	border-bottom: 1px solid !important;
	border-color: rgba(250, 250, 250, 0.2) !important;
}
.campaign-creator-root ul.ant-menu.ant-menu-dark.ant-menu-root.ant-menu-vertical {
	background: rgba(260, 260, 260, 0.1) !important;
	min-height: 100% !important;
}
.ant-tabs-tabpane.ant-tabs-tabpane-active > div > .ant-row {
	padding-top: 0px !important;
	padding-left: 0px !important;
}
.dashboard-container .optimizations-card .ant-tabs-top-bar {
	padding: 10px 2px 0px;
}
.campaign-creator-root ul.ant-menu.ant-menu-dark.ant-menu-root.ant-menu-vertical {
	background: rgba(255, 255, 255, 0.1) !important;
	min-height: fit-content !important;
}
.ant-tabs-tabpane.ant-tabs-tabpane-active > div > .ant-row {
	padding-left: 0px !important;
	padding-top: 0px !important;
	margin-top: 0px !important;
}
.dashboard-container .optimizations-card .ant-tabs-top-bar {
	padding: 0px !important;
	padding-top: 15px !important;
}
.ant-list-split .ant-list-item {
	border-bottom: 1px solid;
	border-color: rgba(250, 250, 250, 0.2) !important;
}
.dashboard-container .top-ads-box .dynamic-video-scroll-box,
.dashboard-container .your-inventory-box .dynamic-video-scroll-box {
	height: 500px !important;
	float: left !important;
	border-top: 1px solid;
	border-color: rgba(250, 250, 250, 0.3) !important;
}
// .dashboard-container .your-order-card .ant-card-body {
// 	height: 90%;
// 	padding: 0;
// 	margin-top: 0px !important;
// }
.top-ads-filter {
	width: 40% !important;
}
.live-monitor-filter {
	width: 100%;
}
.ant-col.auto-height.border-top.ant-col-md-24.ant-col-lg-24.ant-col-xl-6.ant-col-xxl-6 .live-monitor-filter {
	padding-bottom: 20px !important;
	padding-top: 5px;
	line-height: 45px;
}
.live-monitor-filter {
	width: 70%;
	text-align: right !important;
}
.top-ads-filter {
	width: 45% !important;
	text-align: right !important;
}

.app-layout header .partner-autocomplete > span + div,
.app-layout header .partner-autocomplete > span + div *:active {
	padding-left: 10px !important;
}

.ant-card.your-order-card.tab-card.ant-card-small {
	height: 100% !important;
	// overflow: auto !important;
}
.dashboard-container .your-inventory-box .your-inventory-filter {
	display: flex;
	justify-content: space-between;
	padding-bottom: 24px !important;
	padding-top: 15px !important;
	border-bottom: 1px solid;
	border-color: rgba(250, 250, 250, 0.2) !important;
	width: 70% !important;
	float: right !important;
}
.dashboard-container .chart-top-text {
	background: transparent !important;
	font-size: 25px !important;
	height: 55px;
	padding-bottom: 15px !important;
	padding-top: 5px !important;
	font-weight: 700 !important;
}
.ant-tabs {
	margin-top: 0px !important;
}
.login-page input.pf-c-form-control + button {
	height: 50px !important;
	margin-top: -4px !important;
}
.top-ads-filter {
	width: 100% !important;
	text-align: right !important;
}
.ant-col.border-bottom.live-monitoring-row.ant-col-md-24.ant-col-lg-24.ant-col-xl-24.ant-col-xxl-24 {
	width: 97% !important;
	margin-left: auto;
	margin-right: auto;
	text-align: center !important;
}
.ant-col.border-bottom.live-monitoring-row.ant-col-md-24.ant-col-lg-24.ant-col-xl-24.ant-col-xxl-24 {
	width: 96% !important;
	margin-left: auto !important;
	margin-right: auto !important;
	text-align: center !important;
}
.ant-calendar {
	background-color: rgba(0, 0, 0, 0.8) !important;
	color: #fff !important;
}
.ant-calendar-header .ant-calendar-century-select,
.ant-calendar-header .ant-calendar-decade-select,
.ant-calendar-header .ant-calendar-month-select,
.ant-calendar-header .ant-calendar-year-select {
	display: inline-block;
	padding: 0 2px;
	color: #fff !important;
	font-weight: 500;
	line-height: 40px;
}
.ant-calendar-month-panel {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10;
	background: rgba(0, 0, 0, 0.9) !important;
	border-radius: 4px;
	border: 1px solid #fff !important;
	outline: none;
}
.ant-calendar-month-panel-month {
	display: inline-block;
	height: 24px;
	margin: 0 auto;
	padding: 0 8px;
	color: rgba(0, 0, 0, 0.6) !important;
	line-height: 24px;
	text-align: center;
	background: transparent;
	border-radius: 2px;
	-webkit-transition: background 0.3s ease;
	transition: background 0.3s ease;
	color: #fff !important;
	border: 1px solid;
	border-color: rgba(250, 250, 250, 0.5) !important;
	border-radius: 7px !important;
	line-height: 20px !important;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-century-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-decade-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-month-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-year-select {
	color: rgba(250, 250, 250, 0.8) !important;
}
.ant-calendar-date {
	color: rgba(250, 250, 250, 0.6) !important;
}
.ant-calendar-range .ant-calendar-in-range-cell:before {
	background: rgba(250, 250, 250, 0.2) !important;
}
.ant-calendar-date:hover {
	background: rgba(250, 250, 250, 0.2) !important;
}
.ant-calendar-range .ant-calendar-input:hover,
.ant-calendar-range .ant-calendar-time-picker-input:hover {
	border-color: transparent !important;
	border-right-width: 1px !important;
}
.tab-card .ant-tabs-tab {
	font-size: 16px !important;
	font-weight: 700 !important;
	color: #fff !important;
	border: 0;
	padding: 10px 15px !important;
	border-radius: 50px !important;
	background: none !important;
	margin-right: 10px;
	min-width: 75px !important;
	border: none !important;
}
.tab-card .ant-tabs-tab:active {
	border-bottom: 5px !important;
	border-color: rgba(250, 250, 250, 0.5) !important;
}
.ant-calendar-range .ant-calendar-input,
.ant-calendar-range .ant-calendar-time-picker-input {
	color: rgba(250, 250, 250, 0.6) !important;
	background-color: rgba(250, 250, 250, 0.2) !important;
}
.live-monitor-filter {
	width: 25% !important;
	text-align: right !important;
}
/*
  .dashboard-container .your-inventory-title, .dashboard-container .top-performing-title {
	  background: transparent !important;
	  font-size: 25px !important;
	  text-align: left !important;
	  font-weight: 700 !important;
	  width: 40%;
  }
  
  .dashboard-container .your-inventory-box .your-inventory-filter {
	  display: flex;
	  justify-content: space-between;
	  padding-bottom: 24px !important;
	  padding-top: 15px !important;
	  border-bottom: 1px solid;
	  border-color: rgba(250, 250, 250, 0.2) !important;
	  width: 50% !important;
	  float: right !important;
	  text-align: right !important;
  }*/
/* Laptop / Desktop*/

@media screen and (max-width: 1328px) {
	.dashboard-container .your-inventory-box .inventory-scroll-box {
		height: 460px !important;
		width: 100% !important;
	}
	.dashboard-container .live-monitoring h3 {
		color: #fff;
		font-size: 25px !important;
		padding: 0 30px 0 15px;
		margin: auto !important;
		width: 50% !important;
		float: left !important;
	}
	span.ant-calendar-picker.ant-calendar-picker-small {
		height: 35px !important;
		margin-top: 2px !important;
		float: right;
		width: 50% !important;
	}
}

@media screen and (min-width: 1250px) {
	.dashboard-container .your-inventory-box .inventory-scroll-box {
		height: 460px !important;
		width: 100% !important;
	}
	.ant-col.auto-height.border-top.ant-col-md-24.ant-col-lg-24.ant-col-xl-6.ant-col-xxl-6 {
		width: 25% !important;
	}
}

@media screen and (min-width: 1225px) and (max-width: 1580px) {
	.dashboard-container .your-inventory-box .inventory-scroll-box {
		height: 460px !important;
		width: 100% !important;
	}
	.live-monitor-filter {
		width: 50% !important;
		text-align: right !important;
	}
	.dashboard-container .your-inventory-box .your-inventory-filter {
		display: flex;
		justify-content: space-between;
		padding-bottom: 24px !important;
		padding-top: 15px !important;
		border-bottom: 1px solid;
		border-color: rgba(250, 250, 250, 0.2) !important;
		width: 100% !important;
		float: right !important;
		text-align: right !important;
	}
}

@media screen and (min-width: 1225px) {
	.dashboard-container .your-inventory-box .inventory-scroll-box {
		height: 460px !important;
		width: 100% !important;
	}
	.live-monitor-filter {
		width: 50% !important;
		text-align: right !important;
	}
	.dashboard-container .your-inventory-box .your-inventory-filter {
		display: flex;
		justify-content: space-between;
		padding-bottom: 24px !important;
		padding-top: 15px !important;
		border-bottom: 1px solid;
		border-color: rgba(250, 250, 250, 0.2) !important;
		width: 100% !important;
		float: right !important;
		text-align: right !important;
	}
}

@media screen and (min-width: 1024px) and (max-width: 1280px) {
	.dashboard-container .your-inventory-box .inventory-scroll-box {
		height: 460px !important;
		width: 100% !important;
	}
	.ant-col.border-bottom.ant-col-md-12.ant-col-lg-12.ant-col-xl-6.ant-col-xxl-6 {
		width: 24% !important;
	}
	.ant-col.auto-height.border-top.ant-col-md-24.ant-col-lg-24.ant-col-xl-6.ant-col-xxl-6 {
		width: 24% !important;
	}
	.dashboard-container .dynamic-video-row .video-data-text {
		font-size: 12px !important;
	}
}

@media screen and (min-width: 1025px) {
	.dashboard-container .your-inventory-box .inventory-scroll-box {
		height: 460px !important;
		width: 100% !important;
	}
	.ant-col.border-bottom.ant-col-md-12.ant-col-lg-12.ant-col-xl-6.ant-col-xxl-6 {
		width: 25% !important;
		border-radius: 25px !important;
	}
}

@media screen and (max-width: 1080px) {
	.dashboard-container .your-inventory-box .inventory-scroll-box {
		height: 460px !important;
		width: 100% !important;
	}
	.ant-row {
		position: relative;
		height: auto;
		margin-right: 0;
		margin-left: 0;
		zoom: 1;
		display: block;
		box-sizing: border-box;
		padding-left: 0px !important;
		padding-top: 0px !important;
		padding-bottom: 25px !important;
	}
	.dashboard-container .dashboard-row > div {
		border-radius: 0px !important;
	}
}

@media screen and (max-width: 1024px) {
	.dashboard-container .your-inventory-box .inventory-scroll-box {
		height: 460px !important;
		width: 100% !important;
	}
	.ant-row {
		padding-left: 0px !important;
		padding-top: 0px !important;
		padding-bottom: 25px !important;
	}
	.dashboard-container .dashboard-row > div {
		border-radius: 0px !important;
	}
}

@media screen and (min-width: 1250px) {
	.dashboard-container .your-inventory-box .inventory-scroll-box {
		height: 460px !important;
		width: 100% !important;
	}
	.ant-col.auto-height.border-top.ant-col-md-24.ant-col-lg-24.ant-col-xl-6.ant-col-xxl-6 {
		width: 25% !important;
	}
	.ant-select.ant-select-enabled {
		height: 35px !important;
		width: 150px !important;
	}

}

@media screen and (min-width: 1500px) {
	.dashboard-container .your-inventory-box .inventory-scroll-box {
		height: 95% !important;
		width: 100% !important;
	}
	.dashboard-container{
		height: 100% !important;
	}
	.dashboard-row{
		height: 100% !important;
	}
	.ant-col.border-bottom.ant-col-md-12.ant-col-lg-12.ant-col-xl-6.ant-col-xxl-6{
		height: 98% !important;
	}
	.ant-col.border-bottom.border-left.border-right.ant-col-md-12.ant-col-lg-12.ant-col-xl-12.ant-col-xxl-12{
		height: 98% !important;
	}
	.ant-card.your-inventory-box.ant-card-small{
		height: 100% !important;
	}
	.ant-card-body{
		height: 100% !important;
	}
	.inventory-scroll-box{
		height: 100% !important;
	}
}


@media screen and (min-width: 1025px) and (max-width: 1249px) {
	.dashboard-container .your-inventory-box .inventory-scroll-box {
		height: 460px !important;
		width: 100% !important;
	}
	.ant-select.ant-select-enabled {
		height: 35px !important;
		float: left !important;
	}
	.dashboard-container .your-inventory-title,
	.dashboard-container .top-performing-title {
		width: 100% !important;
	}
	.dashboard-container .your-inventory-box .your-inventory-filter {
		width: 100% !important;
		float: left !important;
	}
	.dashboard-container .your-inventory-box .inventory-scroll-box {
		height: 380px !important;
		width: 100% !important;
		overflow: auto !important;
	}
}

@media screen and (min-width: 1025px) and (max-width: 1224px) {
	.dashboard-container .your-inventory-box .inventory-scroll-box {
		height: 460px !important;
		width: 100% !important;
	}
	.ant-col.border-bottom.ant-col-md-12.ant-col-lg-12.ant-col-xl-6.ant-col-xxl-6 {
		width: 100% !important;
		border-radius: 0px !important;
		margin-left: 0px !important;
		height: 845px !important;
	}
	.ant-col.auto-height.border-top.ant-col-md-24.ant-col-lg-24.ant-col-xl-6.ant-col-xxl-6 {
		width: 100% !important;
	}
}

@media screen and (min-width: 1220px) and (max-width: 1250px) {
	.dashboard-container .your-inventory-box .inventory-scroll-box {
		height: 460px !important;
		width: 100% !important;
	}
	.ant-row {
		position: relative;
		height: auto;
		margin-right: 0;
		margin-left: 0;
		zoom: 1;
		display: block;
		box-sizing: border-box;
		padding-left: 0px !important;
		padding-top: 0px !important;
		padding-bottom: 0px !important;
	}
	.dashboard-container .live-monitoring {
		display: block;
		/* align-items: center; */
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		width: 50% !important;
	}
	.ant-col.auto-height.border-top.ant-col-md-24.ant-col-lg-24.ant-col-xl-6.ant-col-xxl-6 {
		width: 25% !important;
	}
	.dashboard-container .chart-top-text {
		background: transparent !important;
		font-size: 25px !important;
		height: 55px;
		padding-bottom: 15px !important;
		padding-top: 5px !important;
		font-weight: 700 !important;
		width: 90% !important;
	}
}

/* Laptop / Desktop*/


/*TABLET IPAD*/
@media screen and (min-width: 768px) and (max-width: 1024px) {
	.dashboard-container .your-inventory-box .inventory-scroll-box {
		height: 460px !important;
		width: 100% !important;
	}
	.ant-row {
		position: relative;
		height: auto;
		margin-right: 0;
		margin-left: 0;
		zoom: 1;
		display: block;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		padding-left: 0px !important;
		padding-top: 0px !important;
		padding-bottom: 0px !important;
	}
	.ant-col.border-bottom.border-left.border-right.ant-col-md-12.ant-col-lg-12.ant-col-xl-12.ant-col-xxl-12 {
		width: 100% !important;
		margin: 0px;
	}
	.ant-col.auto-height.border-top.ant-col-md-24.ant-col-lg-24.ant-col-xl-6.ant-col-xxl-6 {
		width: 100% !important;
		margin: 0px !important;
	}
	.ant-col.border-top.auto-height.border-left.border-right.ant-col-md-24.ant-col-lg-24.ant-col-xl-12.ant-col-xxl-12 {
		width: 100% !important;
		margin: 0px !important;
	}
	.slick-slide.slick-active.slick-current {
		width: 738px !important;
	}
	.ant-col.border-bottom.ant-col-md-12.ant-col-lg-12.ant-col-xl-6.ant-col-xxl-6 {
		width: 100% !important;
		margin: 0px !important;
		height: 845px !important;
	}
	.ant-col.border-bottom.ant-col-md-12.ant-col-lg-12.ant-col-xl-6.ant-col-xxl-6 {
		width: 100% !important;
		margin: 0px !important;
		height: 845px !important;
		border-radius: 0px !important;
	}
	.ant-col.border-bottom.border-left.border-right.ant-col-md-12.ant-col-lg-12.ant-col-xl-12.ant-col-xxl-12 {
		width: 100% !important;
		margin: 0px;
		border-radius: 0px !important;
		height: 845px !important;
	}
	.dashboard-container .your-inventory-box .inventory-scroll-box {
		height: 845px !important;
		width: 100% !important;
	}
	.dashboard-container .your-order-card .card-scroll-content {
		height: 800px !important;
		font-size: 0.8rem;
	}
	.ant-col.auto-height.border-top.ant-col-md-24.ant-col-lg-24.ant-col-xl-6.ant-col-xxl-6 {
		width: 100% !important;
		margin: 0px !important;
		height: 845px !important;
		border-radius: 0px !important;
	}
	.ant-col.border-top.auto-height.border-left.border-right.ant-col-md-24.ant-col-lg-24.ant-col-xl-12.ant-col-xxl-12 {
		width: 100% !important;
		margin: 0px !important;
		height: 845px !important;
		border-radius: 0px !important;
	}
	.dashboard-container .promote-btn-wrapper {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding-top: 0 !important;
		height: 85px !important;
		width: inherit !important;
		background-color: transparent !important;
		padding-bottom: 0 !important;
		margin: 0px !important;
		z-index: 9000 !important;
	}
	.ant-col.border-bottom.border-left.border-right.ant-col-md-12.ant-col-lg-12.ant-col-xl-12.ant-col-xxl-12 {
		width: 100% !important;
		margin: 0px;
		border-radius: 0px !important;
		height: 770px !important;
	}
	.campaign-review-cart .signature-pad canvas,
	.dashboard-container .signature-pad canvas {
		margin: auto;
		background: rgba(0, 0, 0, 0.7) !important;
		border: 1px solid;
		border-color: hsla(0, 0%, 98%, 0.5) !important;
		/*width: 100% !important;*/
		border-radius: 0px !important;
	}
	.dashboard-container .your-inventory-title,
	.dashboard-container .top-performing-title {
		background: transparent !important;
		font-size: 25px !important;
		text-align: left !important;
		font-weight: 700 !important;
		width: 100%;
	}
	.dashboard-container .your-inventory-box .your-inventory-filter {
		display: flex;
		justify-content: space-between;
		padding-bottom: 14px !important;
		padding-top: 15px !important;
		border-bottom: 1px solid;
		border-color: rgba(250, 250, 250, 0.2) !important;
		width: 100% !important;
		float: right !important;
	}
}

@media screen and (min-width:768px) and (max-width: 1224px) {
	.dashboard-container .your-inventory-box .inventory-scroll-box {
		height: 460px !important;
		width: 100% !important;
	}
	.ant-col.auto-height.border-top.ant-col-md-24.ant-col-lg-24.ant-col-xl-6.ant-col-xxl-6 {
		width: 100% !important;
	}
	.ant-select.ant-select-enabled {
		height: 35px !important;
	}
	.dashboard-container .your-order-card .card-scroll-content {
		height: 100vh !important;
	}
	.dashboard-container .promote-btn-wrapper {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding-top: 0 !important;
		height: 85px !important;
		width: inherit !important;
		background-color: transparent !important;
		padding-bottom: 0 !important;
		margin: 0px !important;
		z-index: 9000 !important;
	}
	.ant-col.border-bottom.border-left.border-right.ant-col-md-12.ant-col-lg-12.ant-col-xl-12.ant-col-xxl-12 {
		width: 100% !important;
		margin: 0px;
		border-radius: 0px !important;
		height: 100vh !important;
	}
	.ant-col.auto-height.border-top.ant-col-md-24.ant-col-lg-24.ant-col-xl-6.ant-col-xxl-6 {
		width: 100% !important;
		height: 100vh !important;
	}
	.dashboard-container .your-order-card .card-scroll-content {
		margin-bottom: -100px !important;
	}
	.ant-col.auto-height.border-top.ant-col-md-24.ant-col-lg-24.ant-col-xl-6.ant-col-xxl-6 {
		width: 26% !important;
	}
	.app-layout header .partner-autocomplete {
		margin-left: 10px;
		margin-right: 30px;
		width: 45% !important;
	}
	.dashboard-container .your-inventory-box .your-inventory-filter {
		display: flex;
		justify-content: space-between;
		padding-bottom: 24px !important;
		padding-top: 15px !important;
		border-bottom: 1px solid;
		border-color: rgba(250, 250, 250, 0.2) !important;
		width: 100% !important;
		float: right !important;
		text-align: right !important;
	}
	.ant-select.ant-select-enabled {
		height: 35px !important;
		float: left !important;
	}
	.ant-col.auto-height.border-top.ant-col-md-24.ant-col-lg-24.ant-col-xl-6.ant-col-xxl-6 {
		width: 100% !important;
	}

	/*DONUT*/
	.pf-c-chart > svg {
		pointer-events: all;
		width: 100% !important;
		height: 0%;
		overflow: auto;
		/*display: block;*/
		height: 450px !important;
		position: relative;
	}
	.ant-row {
		position: relative;
		height: auto;
		margin-right: 0;
		margin-left: 0;
		zoom: 1;
		display: block;
		box-sizing: border-box;
		padding-left: 0px !important;
		padding-top: 0px !important;
		padding-bottom: 0px !important;
	}
	.ant-col.auto-height.border-top.ant-col-md-24.ant-col-lg-24.ant-col-xl-6.ant-col-xxl-6 {
		width: 100% !important;
		margin-left: 0px !important;
		margin-top: 0px !important;
		border-radius: 0px !important;
	}
	.ant-col.border-top.auto-height.border-left.border-right.ant-col-md-24.ant-col-lg-24.ant-col-xl-12.ant-col-xxl-12 {
		width: 100% !important;
		margin: 0px !important;
	}
}
/*TABLET IPAD*/

/* Mobile */

@media screen and (min-width: 1px) and (max-width: 640px) {
	.dashboard-container .your-inventory-box .inventory-scroll-box {
		height: 460px !important;
		width: 100% !important;
	}
	.app-layout header .dealer-info {
		display: flex;
		align-items: center;
	}
	.app-layout header .partner-autocomplete {
		margin-left: 10px;
		margin-right: 15px !important;
		width: 175px !important;
	}
	.app-layout header .dealer-info {
		display: flex;
		align-items: center;
		/*width: 75% !important;*/
	}
	.dashboard-container .dashboard-row > div {
		height: 475px !important;
		overflow: hidden;
		padding: 15px;
		background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)) !important;
	}
	.your-inventory-filter > div {
		width: 50% !important;
	}
	.ant-select-selection--single .ant-select-selection__rendered {
		margin-right: 10px !important;
	}
	.ant-select.ant-select-enabled,
	.ant-select.ant-select-focussed {
		/* border: 0 !important; */
		border: 1px solid !important;
		border-radius: 50px !important;
		padding: 5px !important;
		box-shadow: none !important;
		width: 100% !important;
	}
	.app-layout header .anticon {
		margin: 0px 5px !important;
	}
	.app-layout header {
		background: rgba(35, 35, 39, 0.9) !important;
		padding: 0px !important;
		padding-right: 10px !important;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		line-height: 0.6em;
		color: #fff;
	}
	.app-layout header .dealer-info {
		width: 80% !important;
	}
	/*MODAL UPDATE*/
	.ant-col.border-bottom.ant-col-md-12.ant-col-lg-12.ant-col-xl-6.ant-col-xxl-6 {
		width: 100% !important;
	}

	.ant-col.border-bottom.ant-col-md-12.ant-col-lg-12.ant-col-xl-6.ant-col-xxl-6 {
		width: 100% !important;
		margin: 0px !important;
	}
	.ant-col.border-bottom.border-left.border-right.ant-col-md-12.ant-col-lg-12.ant-col-xl-12.ant-col-xxl-12 {
		width: 100% !important;
		margin-left: 0px;
	}
	.ant-col.auto-height.border-top.ant-col-md-24.ant-col-lg-24.ant-col-xl-6.ant-col-xxl-6 {
		width: 100% !important;
		margin: 0px;
	}
	.ant-row {
		position: relative;
		height: auto;
		margin-right: 0;
		margin-left: 0;
		zoom: 1;
		display: block;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		padding-left: 0px !important;
		padding-top: 25px !important;
		padding-bottom: 25px !important;
	}
	.ant-col.border-top.auto-height.border-left.border-right.ant-col-md-24.ant-col-lg-24.ant-col-xl-12.ant-col-xxl-12 {
		width: 100% !important;
		margin-left: 0px !important;
	}

	.dashboard-container .dashboard-row > div {
		border-radius: 0px !important;
	}
	.dashboard-container .top-ads-box .top-ads-filter,
	.dashboard-container .your-inventory-box .top-ads-filter {
		width: 100% !important;
	}
	.ant-select.ant-select-enabled,
	.ant-select.ant-select-focussed {
		width: 85% !important;
	}
	.app-layout header {
		background: rgba(35, 35, 39, 0.9) !important;
		padding: 0px !important;
		padding-right: 10px !important;
		padding-left: 10px !important;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		line-height: 0.6em;
		color: #fff;
	}
	.app-layout header .partner-autocomplete {
		margin-left: 10px;
		margin-right: 15px !important;
		width: 100% !important;
	}
	.app-layout header .partner-autocomplete {
		margin-left: 10px;
		margin-right: 15px !important;
		width: 80% !important;
	}
	.ant-row {
		position: relative;
		height: auto;
		margin-right: 0;
		margin-left: 0;
		zoom: 1;
		display: block;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		padding-left: 0px !important;
		padding-top: 0px !important;
		padding-bottom: 25px !important;
	}
}

@media screen and (min-width: 320px) and (max-width: 480px) {
	.dashboard-container .your-inventory-box .inventory-scroll-box {
		height: 460px !important;
		width: 100% !important;
	}
	.your-inventory-filter > div {
		width: 47% !important;
	}
	.dashboard-container .your-inventory-box .your-inventory-filter {
		display: flex;
		justify-content: space-between;
		padding-bottom: 14px !important;
		padding-top: 15px !important;
		border-bottom: 1px solid;
		border-color: rgba(250, 250, 250, 0.2) !important;
		width: 100% !important;
		float: left !important;
	}
	.ant-input {
		height: 45px !important;
		width: 97% !important;
	}
	.dashboard-container .dashboard-row > div {
		border-radius: 0px !important;
	}
	.dashboard-container .your-inventory-title,
	.dashboard-container .top-performing-title {
		background: transparent !important;
		font-size: 25px !important;
		text-align: left !important;
		font-weight: 700 !important;
		width: 100% !important;
		margin-top: 15px !important;
	}
}

@media screen and (min-width: 360px) and (max-width: 640px) {
	.dashboard-container .your-inventory-box .inventory-scroll-box {
		height: 460px !important;
		width: 100% !important;
	}
	.ant-col.border-bottom.ant-col-md-12.ant-col-lg-12.ant-col-xl-6.ant-col-xxl-6 {
		width: 100% !important;
		margin: 0px !important;
		/*height: 500px !important;*/
	}
	.ant-input {
		height: 35px !important;
		width: 55% !important;
	}
	.ant-select.ant-select-enabled,
	.ant-select.ant-select-focussed {
		width: 65% !important;
		height: 35px !important;
	}
	.ant-select-selection__rendered {
		line-height: 22px !important;
	}
	.dashboard-container .dashboard-row > div {
		height: 620px !important;
		overflow: hidden;
		padding: 15px;
		background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)) !important;
	}
	.ant-col.border-bottom.ant-col-md-12.ant-col-lg-12.ant-col-xl-6.ant-col-xxl-6 {
		width: 100% !important;
		margin: 0px !important;
		height: 100vh !important;
	}
	.ant-select-arrow {
		top: 40% !important;
	}
	.ant-col.border-bottom.ant-col-md-12.ant-col-lg-12.ant-col-xl-6.ant-col-xxl-6 {
		/*height: 500px !important;*/
		height: 100vh !important;
	}
	.ant-col.border-bottom.border-left.border-right.ant-col-md-12.ant-col-lg-12.ant-col-xl-12.ant-col-xxl-12 {
		width: 100% !important;
		margin: 0px;
		border-radius: 0px !important;
		height: 890px !important;
	}
	.ant-row {
		padding-left: 0 !important;
		padding-top: 0 !important;
		padding-bottom: 25px !important;
		padding-right: 0px !important;
	}
	/*.dashboard-container .your-inventory-box .inventory-scroll-box {
	  height: 710px !important;
	  width: 100% !important;
	}*/
}

@media screen and (min-width: 540px) and (max-width: 720px) {
	.dashboard-container .your-inventory-box .inventory-scroll-box {
		height: 460px !important;
		width: 100% !important;
	}
	.dashboard-container .your-inventory-box .your-inventory-filter {
		width: 100% !important;
		float: right !important;
	}
	.your-inventory-title,
	.dashboard-container .top-performing-title {
		background: transparent !important;
		font-size: 25px !important;
		text-align: left !important;
		font-weight: 700 !important;
		width: 100% !important;
	}
	.ant-select.ant-select-enabled,
	.ant-select.ant-select-focussed {
		width: 65% !important;
	}
	.ant-col.border-bottom.border-left.border-right.ant-col-md-12.ant-col-lg-12.ant-col-xl-12.ant-col-xxl-12 {
		width: 100% !important;
		margin-left: 0px;
		height: 770px !important;
	}
	.ant-select.ant-select-enabled,
	.ant-select.ant-select-focussed {
		width: 65% !important;
	}
	.ant-col.border-bottom.border-left.border-right.ant-col-md-12.ant-col-lg-12.ant-col-xl-12.ant-col-xxl-12 {
		width: 100% !important;
		margin-left: 0px;
		height: 630px !important;
	}
	.ant-col.border-bottom.ant-col-md-12.ant-col-lg-12.ant-col-xl-6.ant-col-xxl-6 {
		width: 100% !important;
		margin: 0px !important;
		height: 650px !important;
	}
}

@media screen and (max-width: 768px) {
	.dashboard-container .your-inventory-box .inventory-scroll-box {
		height: 460px !important;
		width: 100% !important;
	}
	.ant-row {
		position: relative;
		height: auto;
		margin-right: 0;
		margin-left: 0;
		zoom: 1;
		display: block;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		padding-left: 0px !important;
		padding-top: 0px !important;
		padding-bottom: 0px !important;
	}
	.ant-col.border-bottom.border-left.border-right.ant-col-md-12.ant-col-lg-12.ant-col-xl-12.ant-col-xxl-12 {
		width: 100% !important;
		margin: 0px;
	}
	.ant-col.auto-height.border-top.ant-col-md-24.ant-col-lg-24.ant-col-xl-6.ant-col-xxl-6 {
		width: 100% !important;
		margin: 0px !important;
	}
	.ant-col.border-top.auto-height.border-left.border-right.ant-col-md-24.ant-col-lg-24.ant-col-xl-12.ant-col-xxl-12 {
		width: 100% !important;
		margin: 0px !important;
	}
	.slick-slide.slick-active.slick-current {
		/*width: 738px !important;*/
	}
	.ant-col.border-bottom.ant-col-md-12.ant-col-lg-12.ant-col-xl-6.ant-col-xxl-6 {
		width: 100% !important;
		margin: 0px !important;
		height: 845px !important;
	}
	.ant-col.border-bottom.ant-col-md-12.ant-col-lg-12.ant-col-xl-6.ant-col-xxl-6 {
		width: 100% !important;
		margin: 0px !important;
		height: 845px !important;
		border-radius: 0px !important;
	}
	.ant-col.border-bottom.border-left.border-right.ant-col-md-12.ant-col-lg-12.ant-col-xl-12.ant-col-xxl-12 {
		width: 100% !important;
		margin: 0px;
		border-radius: 0px !important;
		height: 845px !important;
	}
	.dashboard-container .your-inventory-box .inventory-scroll-box {
		height: 845px !important;
		width: 100% !important;
	}
	.dashboard-container .your-order-card .card-scroll-content {
		height: 450px !important;
		font-size: 0.8rem;
	}
	.ant-col.auto-height.border-top.ant-col-md-24.ant-col-lg-24.ant-col-xl-6.ant-col-xxl-6 {
		width: 100% !important;
		margin: 0px !important;
		height: 845px !important;
		border-radius: 0px !important;
	}
	.ant-col.border-top.auto-height.border-left.border-right.ant-col-md-24.ant-col-lg-24.ant-col-xl-12.ant-col-xxl-12 {
		width: 100% !important;
		margin: 0px !important;
		height: 845px !important;
		border-radius: 0px !important;
	}
	.dashboard-container .promote-btn-wrapper {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding-top: 0 !important;
		height: 85px !important;
		width: inherit !important;
		background-color: transparent !important;
		padding-bottom: 0 !important;
		margin: 0px !important;
		z-index: 9000 !important;
	}
	/*.ant-col.border-bottom.border-left.border-right.ant-col-md-12.ant-col-lg-12.ant-col-xl-12.ant-col-xxl-12 {
	  width: 100% !important;
	  margin: 0px;
	  border-radius: 0px !important;
	  height: 770px !important;
	}*/
	.campaign-review-cart .signature-pad canvas,
	.dashboard-container .signature-pad canvas {
		margin: auto;
		background: rgba(0, 0, 0, 0.7) !important;
		border: 1px solid;
		border-color: hsla(0, 0%, 98%, 0.5) !important;
		width: 100% !important;
		border-radius: 0px !important;
	}
	.dashboard-container .your-inventory-title,
	.dashboard-container .top-performing-title {
		background: transparent !important;
		font-size: 25px !important;
		text-align: left !important;
		font-weight: 700 !important;
		width: 100%;
	}
	.dashboard-container .your-inventory-box .your-inventory-filter {
		display: flex;
		justify-content: space-between;
		padding-bottom: 14px !important;
		padding-top: 15px !important;
		border-bottom: 1px solid;
		border-color: rgba(250, 250, 250, 0.2) !important;
		width: 100% !important;
		float: right !important;
	}
	.dashboard-container .your-inventory-box .inventory-scroll-box {
		max-height: 835px !important;
		width: 100% !important;
	}
	/*.dashboard-container .your-inventory-box .inventory-scroll-box {
	  height: 365px !important;
	  width: 100% !important;
	}*/
	.dashboard-container .your-inventory-box .inventory-scroll-box {
		height: 405px !important;
		width: 100% !important;
	}
	.ant-col.border-top.auto-height.border-left.border-right.ant-col-md-24.ant-col-lg-24.ant-col-xl-12.ant-col-xxl-12 {
		width: 100% !important;
		margin: 0px !important;
		height: 100vh !important;
		border-radius: 0px !important;
	}
}

/* Mobile */

/*LOGIN*/
/*SELECT LOCATION*/
.css-yk16xz-control {
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: hsl(0, 0%, 0%);
	border-color: hsl(0, 0%, 50%) !important;
	background-color: rgba(0, 0, 0, 0.9) !important;
	border-radius: 25px !important;
	border-style: solid;
	border-width: 1px;
	cursor: default;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	min-height: 38px;
	outline: 0 !important;
	position: relative;
	-webkit-transition: all 100ms;
	transition: all 100ms;
	box-sizing: border-box;
	height: 45px !important;
}

.css-1pahdxg-control {
	background-color: rgba(0, 0, 0, 0.9) !important;
	border-radius: 25px !important;
	border: 1px solid !important;
	border-color: rgba(250, 250, 250, 0.5) !important;
}

input#pf-login-username-id {
	border: 1px solid !important;
	border-color: rgba(250, 250, 250, 0.3) !important;
	border-radius: 50px !important;
	background-color: rgba(0, 0, 0, 0.9);
	height: 45px !important;
}

input#pf-login-username-id {
	border: 1px solid !important;
	border-color: rgba(250, 250, 250, 0.3) !important;
	border-radius: 50px !important;
	background-color: rgba(0, 0, 0, 0.9) !important;
	height: 45px !important;
}
.pf-c-input-group input:not([type="checkbox"]):not([type="radio"]),
.pf-c-input-group textarea {
	flex: 2 1;
	min-width: 0;
	border-bottom-left-radius: 50px !important;
	border-top-left-radius: 50px !important;
	background-color: rgba(0, 0, 0, 0.9) !important;
	border-color: rgba(250, 250, 250, 0.3) !important;
	color: #fff !important;
	height: 45px !important;
}
.pf-c-input-group {
	background-color: transparent !important;
	border-radius: 50px !important;
}
.login-page input.pf-c-form-control + button {
	border: 1px solid #151515 !important;
	height: 45px !important;
	border-top-right-radius: 50px !important;
	border-bottom-right-radius: 50px !important;
	background-color: rgba(0, 0, 0, 0.9) !important;
	border-color: rgba(250, 250, 250, 0.3) !important;
	color: rgba(250, 250, 250, 0.7) !important;
}
.css-yk16xz-control {
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: hsl(0, 0%, 100%);
	border-color: hsl(0, 0%, 80%);
	border-radius: 4px;
	border-style: solid;
	border-width: 1px;
	cursor: default;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	min-height: 38px;
	outline: 0 !important;
	position: relative;
	-webkit-transition: all 100ms;
	transition: all 100ms;
	box-sizing: border-box;
	height: 45px !important;
}
.css-2613qy-menu {
	background-color: rgba(0, 0, 0, 0.8) !important;
	border: 1px solid !important;
	border-color: rgba(250, 250, 250, 0.3) !important;
}
.top-ads-filter {
	width: 45% !important;
	text-align: right !important;
}
.dashboard-container .social-media-list .space-between-row {
	position: relative;
	color: #fff;
	padding: 10px 10px !important;
	align-items: flex-start;
	border-bottom: 1px solid !important;
	border-color: rgba(250, 250, 250, 0.3) !important;
	padding-bottom: 15px !important;
}
.your-inventory-filter > div {
	width: 50% !important;
}
.ant-select-selection__rendered {
	line-height: 22px !important;
}
.ant-select-arrow {
	top: 35% !important;
}
.dashboard-container .top-ads-box .dynamic-video-scroll-box,
.dashboard-container .your-inventory-box .dynamic-video-scroll-box {
	width: 100% !important;
}
.pf-c-input-group input:not([type="checkbox"]):not([type="radio"]),
.pf-c-input-group textarea,
.pf-c-input-group textarea:active,
.pf-c-input-group textarea:enabled,
.pf-c-input-group textarea:disabled,
.pf-c-input-group textarea:hover,
.pf-c-input-group textarea:focus,
input#pf-login-username-id {
	background-color: rgba(0, 0, 0, 0.9) !important;
	color: #fff !important;
}
.dashboard-container .social-media-list .space-between-row .ant-card-meta-detail {
	line-height: 25px !important;
}
.your-inventory-filter > div {
	width: 50% !important;
	/*max-width: 225px !important;*/
}
.ant-input {
	height: 35px !important;
}
.top-ads-filter {
	width: 100% !important;
	text-align: right !important;
}
/*.pf-c-chart {
	  width: 80% !important;
  }*/
.ant-tabs {
	width: 100% !important;
	border-color: currentcolor hsla(0, 0%, 98%, 0.3) hsla(0, 0%, 98%, 0.3) !important;
	border-top: 1px solid hsla(0, 0%, 98%, 0.3) !important;
	margin-top: 10px !important;
}
.ant-card-meta {
	padding-top: 10px !important;
}
/*.dashboard-container .social-media-list .space-between-row .social-data {
	  font-size: 14px!important;
	  line-height: 1rem;
	  margin-top: 10px;
	  margin-right: 10px;
  }*/
.top-ads-filter {
	width: 55% !important;
	text-align: right !important;
}
/*button.pf-c-button.pf-m-control {
	  border-radius: 50px !important;
  }*/
.dashboard-container .live-monitoring h3 {
	color: #fff;
	font-size: 25px !important;
	padding: 0 30px 0 15px;
	margin: auto;
	width: 25%;
	float: left;
}
.dashboard-container .live-monitoring {
	display: block;
	/* align-items: center; */
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	width: 45% !important;
}
span.ant-calendar-picker.ant-calendar-picker-small {
	height: 35px !important;
	margin-top: 2px !important;
	float: right;
}
.dashboard-container .live-monitoring h3 {
	color: #fff;
	font-size: 25px !important;
	padding: 0 30px 0 15px;
	margin: auto !important;
	width: 30% !important;
	float: left !important;
}
.centered-row {
	display: flex;
	justify-content: center;
	padding: 5px 10px;
	z-index: 20000;
	margin-top: -45px !important;
}
span.ant-calendar-picker.ant-calendar-picker-small {
	height: 35px !important;
	margin-top: 2px !important;
	float: left;
}
.dashboard-container .live-monitoring h3 {
	color: #fff;
	font-size: 25px !important;
	padding: 0 30px 0 15px;
	margin: auto !important;
	width: 43% !important;
	float: left !important;
}
/*.ant-card-meta {
	  padding-top: 10px !important;
	  border-bottom: 1px solid;
	  border-color: rgba(250, 250, 250, 0.3);
  }*/
/*.top-ads-filter {
	  width: 100%!important;
	  text-align: right!important; 
  }*/
.login-page input.pf-c-form-control + button {
	border: 1px solid #151515 !important;
	height: 45px !important;
	border-top-right-radius: 50px !important;
	border-bottom-right-radius: 50px !important;
	background-color: rgba(0, 0, 0, 0.9) !important;
	border-color: rgba(250, 250, 250, 0.3) !important;
	color: rgba(250, 250, 250, 0.7) !important;
	margin-top: 0.5px !important;
}
.login-page input.pf-c-form-control + button {
	border: 1px solid #151515 !important;
	height: 50px !important;
	border-top-right-radius: 50px !important;
	border-bottom-right-radius: 50px !important;
	top: -4px !important;
	background-color: rgba(0, 0, 0, 0.9) !important;
	border-color: rgba(250, 250, 250, 0.3) !important;
	color: rgba(250, 250, 250, 0.7) !important;
	margin-top: 0px !important;
}
.dashboard-container .your-inventory-box .your-inventory-filter {
	display: flex;
	justify-content: space-between;
	padding-bottom: 24px !important;
	padding-top: 15px !important;
	border-bottom: 1px solid;
	border-color: rgba(250, 250, 250, 0.2) !important;
	width: 70% !important;
	float: right !important;
	text-align: right !important;
}
/*FILTER TITLES */
.budget-filter.top-ads-filter > span {
	display: none !important;
}
.live-monitor-filter > span {
	display: none !important;
}
.top-ads-filter > span {
	display: none !important;
}
.your-inventory-filter > div > span {
	display: none !important;
}
.pf-c-date-picker__input > div > button {
	border-radius: 50px;
}
button.pf-c-button.pf-m-control:disabled {
	border-radius: 50px !important;
	background-color: #06c !important;
	color: #fff !important;
}
.ant-col.border-bottom.live-monitoring-row.ant-col-md-24.ant-col-lg-24.ant-col-xl-24.ant-col-xxl-24 {
	width: 102% !important;
	margin-left: -20px !important;
	margin-right: -20px !important;
	text-align: center !important;
	border-radius: 0px !important;
}

/* CALENDAR */
.ant-calendar-range .ant-calendar-input,
.ant-calendar-range .ant-calendar-time-picker-input {
	padding: 5px 10px !important;
	color: rgba(250, 250, 250, 0.6) !important;
	background-color: rgba(0, 0, 0, 0.5) !important;
}
.ant-calendar-header .ant-calendar-prev-century-btn,
.ant-calendar-header .ant-calendar-next-century-btn,
.ant-calendar-header .ant-calendar-prev-decade-btn,
.ant-calendar-header .ant-calendar-next-decade-btn,
.ant-calendar-header .ant-calendar-prev-month-btn,
.ant-calendar-header .ant-calendar-next-month-btn,
.ant-calendar-header .ant-calendar-prev-year-btn,
.ant-calendar-header .ant-calendar-next-year-btn {
	color: rgba(250, 250, 250, 0.4) !important;
}
.pf-c-input-group input:not([type="checkbox"]):not([type="radio"]),
.pf-c-input-group textarea {
	height: 50px !important;
}
.login-page input.pf-c-form-control + button {
	margin-top: 4px !important;
}
button,
input {
	color: #fff !important;
}
div.pf-c-login_main-body {
	background-color: rgba(0, 0, 0, 0.7) !important;
	color: #fff !important;
}
.tab-card .ant-tabs-tab {
	font-size: 22px !important;
	font-weight: 500 !important;
	color: #fff !important;
	border: 0;
	padding: 15px !important;
	border-radius: 50px !important;
	background: none !important;
	margin-right: 15px !important;
	min-width: 75px !important;
	border: none !important;
	border-color: transparent;
}
.ant-calendar {
	border: 1px solid !important;
	border-color: rgba(250, 250, 250, 0.4) !important;
}
.ant-tabs-tab-active {
	font-weight: 700 !important;
}
.ant-tabs-tab-active::before {
	border-top: 3px solid !important;
	border-radius: 0px !important;
	margin-top: 55px !important;
	border-color: rgba(250, 250, 250, 0.5) !important;
}
.ant-tabs-nav-container {
	width: 25% !important;
	margin-left: auto !important;
	margin-right: auto !important;
}
.dashboard-container .optimizations-card .ant-tabs-nav-scroll {
	padding-left: 0px !important;
	padding-bottom: 0px !important;
}
.ant-tabs-nav .ant-tabs-tab-active {
	color: #fff !important;
	font-weight: 900 !important;
	text-decoration: none !important;
}
button.pf-c-button.pf-m-control > svg {
	color: #777777 !important;
}
button.pf-c-button.pf-m-control:disabled > svg {
	color: #ffffff !important;
}
.ant-avatar > img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 50px !important;
}
.pf-c-calendar-month {
	background-color: rgba(0, 0, 0, 0.8) !important;
}
thead.pf-c-calendar-month__days {
	color: #fff !important;
}
button#pf-select-toggle-id-14 {
	color: #777 !important;
}
button.pf-c-select__menu-item {
	color: #fff !important;
	background-color: rgba(0, 0, 0, 0.9) !important;
}

/* CALENDAR TOGGLE */

.pf-c-select__toggle {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	min-width: var(--pf-c-select__toggle--MinWidth);
	padding: var(--pf-c-select__toggle--PaddingTop) var(--pf-c-select__toggle--PaddingRight)
		var(--pf-c-select__toggle--PaddingBottom) var(--pf-c-select__toggle--PaddingLeft);
	font-size: var(--pf-c-select__toggle--FontSize);
	font-weight: var(--pf-c-select__toggle--FontWeight);
	line-height: var(--pf-c-select__toggle--LineHeight);
	color: var(--pf-c-select__toggle--Color);
	white-space: nowrap;
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0.9) !important;
	border: none;
}
button#pf-select-toggle-id-30 {
	color: #fff !important;
	background-color: rgba(0, 0, 0, 0.9) !important;
	border-top-left-radius: 50px !important;
	border-bottom-left-radius: 50px !important;
}
.pf-c-calendar-month__header-year {
	color: #fff !important;
	background-color: rgba(0, 0, 0, 0.9) !important;
	border-bottom-right-radius: 50px !important;
	border-bottom-left-radius: 50px !important;
}
input.pf-c-form-control {
	border-bottom-right-radius: 50px !important;
	border-top-right-radius: 50px !important;
	background-color: rgba(0, 0, 0, 0.9) !important;
}
.pf-c-popover.pf-m-right .pf-c-popover__arrow {
	background-color: rgba(250, 250, 250, 0.2) !important;
}
.pf-c-calendar-month__header-year {
	color: #fff !important;
	background-color: transparent !important;
	border-bottom-right-radius: 50px !important;
	border-bottom-left-radius: 50px !important;
}
.pf-c-select__toggle {
	background-color: rgba(0, 0, 0, 0.9) !important;
	border-top-left-radius: 50px !important;
	border-bottom-left-radius: 50px !important;
	padding-left: 10px !important;
	color: #fff !important;
}
.pf-c-select__menu {
	background-color: rgba(0, 0, 0, 0.9) !important;
}
/*DATE PICK BUTTONS */
.pf-c-date-picker__input > div > button {
	border-radius: 25px !important;
}
.pf-c-calendar-month__dates-cell.pf-m-current button.pf-c-calendar-month__date {
	background-color: rgba(250, 250, 250, 0.3) !important;
}
/* PERFORMANCE CHART TABS */
.ant-tabs-nav-container {
	width: 100% !important;
	margin-left: auto !important;
	margin-right: auto !important;
}
.dashboard-container .optimizations-card .ant-tabs-top-bar {
	padding: 0px !important;
	padding-top: 0px !important;
}
.ant-calendar-year-panel {
	background: rgba(0, 0, 0, 0.9) !important;
}
.ant-calendar-year-panel-year {
	display: inline-block;
	height: 24px;
	margin: 0 auto;
	padding: 0 8px;
	color: rgba(250, 250, 250, 0.9) !important;
	line-height: 24px;
	text-align: center;
	background: transparent;
	border-radius: 2px;
	-webkit-transition: background 0.3s ease;
	transition: background 0.3s ease;
	border: 1px solid #333 !important;
}
.pf-c-popover__arrow {
	position: absolute;
	width: var(--pf-c-popover__arrow--Width);
	height: var(--pf-c-popover__arrow--Height);
	pointer-events: none;
	background-color: rgba(250, 250, 250, 0.2) !important;
	box-shadow: var(--pf-c-popover__arrow--BoxShadow);
}
span.dynamic-video-title {
	font-size: 16px !important;
	font-weight: 700 !important;
}
.tab-card .ant-tabs-tab {
	font-size: 18px !important;
}
svg > g > g > g > g > g > g > g > g > g > path {
	stroke-width: 5 !important;
	opacity: 0.8;
	/*background-color: rgba(79, 176, 87, 0.8) !important;*/
}

/*@media screen and (min-width: 1250px) {
	.ant-col.auto-height.border-top.ant-col-md-24.ant-col-lg-24.ant-col-xl-6.ant-col-xxl-6 {
	  width: 26% !important;
	}
  }*/

.react-player__preview {
	width: 80% !important;
	height: 100% !important;
	margin-left: auto;
	margin-right: auto;
}

input#pf-login-password-id {
	border-top-right-radius: 0px !important;
	border-bottom-right-radius: 0px !important;
}

.login-page input.pf-c-form-control + button {
	margin-top: 1px !important;
	margin-bottom: 0px !important;
	height: 57px !important;
}

input#pf-login-username-id {
	border: 1px solid !important;
	border-color: rgba(250, 250, 250, 0.3) !important;
	border-radius: 50px !important;
	background-color: rgba(0, 0, 0, 0.9) !important;
	height: 55px !important;
}

button.pf-c-button.pf-m-primary.pf-m-block {
	height: 55px !important;
}

/*SUCCESS*/

.success-modal-root .ant-modal-content {
	background: rgba(0, 0, 0, 0.8) !important;
	color: #fff;
	border-radius: 25px !important;
	border: 1px solid;
	border-color: rgba(250, 250, 250, 0.3) !important;
	margin-bottom: 25px !important;
	height: 100vh !important;
	display: block;
	position: relative;
}

.success-modal-root .ant-modal-content {
	background: rgba(0, 0, 0, 0.8) !important;
	color: #fff;
	border-radius: 25px !important;
	border: 1px solid;
	border-color: rgba(250, 250, 250, 0.3) !important;
	margin-bottom: 25px !important;
	height: 100vh !important;
	display: block;
	position: relative;
	overflow: auto;
}

.success-modal-root .ant-modal-footer button {
	padding-bottom: inherit !important;
	text-align: center !important;
	min-width: 300px !important;
	margin-bottom: 50px !important;
}
.ant-calendar-disabled-cell-last-of-row .ant-calendar-date {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}
/*
  .ant-calendar-disabled-cell .ant-calendar-date {
	  color: rgba(0,0,0,.25);
	  background: rgba(250, 250, 250, 0.0) !important;
	  background-color: rgba(250, 250, 250, 0.0) !important;
  }
  
  .dashboard-container .live-monitoring h3 {
	  color: #fff;
	  font-size: 25px !important;
	  padding: 0 30px 0 15px;
	  margin: auto !important;
	  width: 50% !important;
	  float: left !important;
  }*/
span.ant-calendar-picker.ant-calendar-picker-small {
	height: 35px !important;
	margin-top: 2px !important;
	float: right !important;
	/* width: 50% !important; */
}
.dashboard-container .live-monitoring {
	display: block;
	align-items: center;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	width: 50% !important;
}
.dashboard-container .live-monitoring h3 {
	color: #fff;
	font-size: 25px !important;
	padding: 0 30px 0 15px;
	margin: auto !important;
	width: 50% !important;
	float: left !important;
	text-align: left !important;
}
span.ant-calendar-picker.ant-calendar-picker-small {
	height: 35px !important;
	margin-top: 2px !important;
	float: none !important;
}
.ant-select.ant-select-enabled {
	height: 35px !important;
	float: right !important;
}

.antSelectLogo.ant-select.ant-select-enabled {
	width: 100% !important;
	border-radius: 0px !important;
	background-color: white !important;
	height: 100% !important;
}


.campaign-creator-root ul.ant-menu.ant-menu-dark.ant-menu-root.ant-menu-vertical .ant-menu-item-selected {
	border-radius: 0px !important;
}

@media screen and (min-width:768px) and (max-width: 1224px) {
	.dashboard-container .dashboard-row > div {
		height: 550px;
		/* width: 25%; */
		overflow: hidden;
		padding: 15px;
		background-image: linear-gradient(rgba(250, 250, 250, 0.1), rgba(0, 0, 0, 0.7)) !important;
		border-radius: 0px !important;
		border: 0px solid !important;
		border-color: rgba(250, 250, 250, 0) !important;
		margin: 10px;
	}
	.ant-col.auto-height.border-top.ant-col-md-24.ant-col-lg-24.ant-col-xl-6.ant-col-xxl-6 {
		width: 100% !important;
	}
	.ant-select.ant-select-enabled {
		height: 35px !important;
	}
	.dashboard-container .your-order-card .card-scroll-content {
		height: 100vh !important;
	}
	.dashboard-container .promote-btn-wrapper {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding-top: 0 !important;
		height: 85px !important;
		width: inherit !important;
		background-color: transparent !important;
		padding-bottom: 0 !important;
		margin: 0px !important;
		z-index: 9000 !important;
	}
	.ant-col.border-bottom.border-left.border-right.ant-col-md-12.ant-col-lg-12.ant-col-xl-12.ant-col-xxl-12 {
		width: 100% !important;
		margin: 0px;
		border-radius: 0px !important;
		height: 100vh !important;
	}
	.ant-col.auto-height.border-top.ant-col-md-24.ant-col-lg-24.ant-col-xl-6.ant-col-xxl-6 {
		width: 100% !important;
		height: 100vh !important;
	}
	.dashboard-container .your-order-card .card-scroll-content {
		margin-bottom: -100px !important;
	}
	.ant-col.auto-height.border-top.ant-col-md-24.ant-col-lg-24.ant-col-xl-6.ant-col-xxl-6 {
		width: 26% !important;
	}
	.app-layout header .partner-autocomplete {
		margin-left: 10px;
		margin-right: 30px;
		width: 45% !important;
	}
	.dashboard-container .your-inventory-box .your-inventory-filter {
		display: flex;
		justify-content: space-between;
		padding-bottom: 24px !important;
		padding-top: 15px !important;
		border-bottom: 1px solid;
		border-color: rgba(250, 250, 250, 0.2) !important;
		width: 100% !important;
		float: right !important;
		text-align: right !important;
	}
	.ant-select.ant-select-enabled {
		height: 35px !important;
		float: left !important;
	}
	.ant-col.auto-height.border-top.ant-col-md-24.ant-col-lg-24.ant-col-xl-6.ant-col-xxl-6 {
		width: 100% !important;
	}
	.dashboard-container .promote-btn-wrapper {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding-top: 0 !important;
		height: 85px !important;
		width: inherit !important;
		background-color: transparent !important;
		padding-bottom: 0 !important;
		margin: 0px !important;
		z-index: 9000 !important;
		margin-top: -55px !important;
	}
	.app-layout header .partner-autocomplete {
		margin-left: 10px;
		margin-right: 30px;
		width: 75% !important;
	}

	/*DONUT*/
	.pf-c-chart > svg {
		pointer-events: all;
		width: 100% !important;
		height: 0%;
		overflow: auto;
		/*display: block;*/
		height: 450px !important;
		position: relative;
	}
	.ant-row {
		position: relative;
		height: auto;
		margin-right: 0;
		margin-left: 0;
		zoom: 1;
		display: block;
		box-sizing: border-box;
		padding-left: 0px !important;
		padding-top: 0px !important;
		padding-bottom: 0px !important;
	}
	.ant-col.auto-height.border-top.ant-col-md-24.ant-col-lg-24.ant-col-xl-6.ant-col-xxl-6 {
		width: 100% !important;
		margin-left: 0px !important;
		margin-top: 0px !important;
		border-radius: 0px !important;
	}
	.ant-col.border-top.auto-height.border-left.border-right.ant-col-md-24.ant-col-lg-24.ant-col-xl-12.ant-col-xxl-12 {
		width: 100% !important;
		margin: 0px !important;
	}
	.dashboard-container .your-inventory-box .inventory-scroll-box {
		height: 100% !important;
		width: 100% !important;
		overflow: auto !important;
	}
	.dashboard-container .live-monitoring {
		display: block;
		align-items: center;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		width: 95% !important;
	}
	.ant-select.ant-select-enabled {
		height: 35px !important;
		float: right !important;
	}
	.ant-select.ant-select-enabled {
		height: 35px !important;
		float: left !important;
		margin-left: 15px !important;
	}
	.ant-carousel .slick-list .slick-slide.slick-active {
		pointer-events: auto;
		width: 20% !important;
	}
	.ant-modal.campaign-creator-root {
		width: 100% !important;
		border: none !important;
		border-color: transparent !important;
		height: 100% !important;
		border-radius: 0px !important;
	}
	.budget-filter.top-ads-filter.ant-select.ant-select-enabled {
		height: 35px !important;
		float: right !important;
		margin-left: 15px !important;
	}
	.ant-select.ant-select-enabled {
		height: 35px !important;
		float: left !important;
		margin-left: 15px !important;
	}
}
/*.ant-calendar-disabled-cell .ant-calendar-date {
	  position: relative;
	  width: auto;
	  color: rgba(0,0,0,.25) !important;
	  background: transparent !important;
	  border: 1px solid transparent;
	  background-color: transparent !important;
	  border-radius: 0px !important;
	  cursor: not-allowed;
  }*/

.ant-calendar-date {
	background: rgba(250, 250, 250, 0.1) !important;
}

.pf-c-login__main {
	background-color: rgba(250, 250, 250, 0.1) !important;
	border: 1px solid !important;
	border-color: rgba(250, 250, 250, 0.2) !important;
	border-radius: 0px !important;
}

.pf-c-login__main {
	background-color: rgba(0, 0, 0, 0.1) !important;
	border: 1px solid !important;
	border-color: rgba(250, 250, 250, 0.2) !important;
	border-radius: 0px !important;
	color: #ffffff !important;
	height: 100vh !important;
	background-image: linear-gradient(to bottom, rgba(250, 250, 250, 0.2) 5%, rgba(0, 0, 0, 0.75)) !important;
	width: 100% !important;
	max-width: 640px !important;
	overflow: auto !important;
}
.pf-c-login.login-page {
	margin-top: 0px !important;
	padding-top: 0px !important;
}

input#feedbackEmail {
	background-color: rgba(0, 0, 0, 0.5) !important;
	border: 1px solid rgba(250, 250, 250, 0.3) !important;
}

textarea#feedbackMsg {
	background-color: rgba(0, 0, 0, 0.5) !important;
	border: 1px solid rgba(250, 250, 250, 0.3) !important;
}

.frf-modal-container {
	display: flex;
	flex-direction: column;
	text-align: left;
	position: absolute;
	right: 12px;
	bottom: 108px;
	background: rgba(0, 0, 0, 0.8) !important;
	max-width: 380px !important;
	width: 80% !important;
	max-height: 70vh;
	border: 1px solid !important;
	border-color: rgba(250, 250, 250, 0.5) !important;
	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow: scroll;
	border-radius: 15px !important;
	font-size: 16px !important;
}

textarea#feedbackMsg {
	color: #fff !important;
}

.frf-modal-container {
	display: flex;
	flex-direction: column;
	text-align: left;
	position: absolute;
	right: 12px;
	bottom: 108px;
	max-width: 380px !important;
	width: 80% !important;
	max-height: 70vh;
	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow: scroll;
	border-radius: 15px !important;
	font-size: 16px !important;
	background: rgba(0, 0, 0, 1) !important;
}

span.frf-modal-feedback-type {
	color: #ffffff !important;
}

.frf-modal-feedback-types .frf-modal-feedback-type {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 500 !important;
	overflow-wrap: anywhere;
	max-width: 100/3;
	padding: 5px !important;
	text-align: center;
	border: 1px solid rgba(250, 250, 250, 0.3) !important;
	cursor: pointer;
	margin-right: 5px !important;
	margin-left: 5px !important;
	border-radius: 50px !important;
}

.frf-modal-label {
	color: rgba(250, 250, 250, 0.9) !important;
	padding-bottom: 8px;
	font-size: 14px;
	display: inline-block;
	font-weight: 500;
}

.frf-trigger-button {
	justify-content: space-between;
	position: absolute;
	right: 12px;
	bottom: 24px;
	white-space: nowrap;
	cursor: pointer;
	/* box-shadow: 0 20px 40px rgb(0 0 0 / 10%); */
	padding: 15px 15px !important;
	outline: 0;
	border-radius: 4px;
	border: none;
	border-radius: 50px !important;
	background-color: rgba(0, 0, 0, 0.3) !important;
	border: 1px solid rgba(250, 250, 250, 0.5) !important;
}

span.frf-modal-feedback-type.frf-modal-feedback-selected {
	background: rgba(250, 250, 250, 0.3) !important;
}

.custom-bt{
	margin-top: 0px !important;
}

.custom-header-bt{
    font-weight: 400;
    background-color:black !important;
  }

  .ant-modal.success-modal-root.login-alert {
	width: 50% !important;
	height: auto !important;
	background-color: transparent; 
}  
.ant-modal.success-modal-root.login-alert .ant-modal-content{
	height: 80% !important;
}