.tab-card {
  @include transparent-background;
  color: $white;
  .ant-card-body {
    padding: 0;
  }
  .ant-card-meta-title {
    color: $white;
    margin-top: 5px;
    @include large-font;
  }
  .ant-card-meta-avatar {
    margin-top: 5px;
    padding-right: 5px;
  }
  .ant-avatar-icon,
  .ant-avatar-circle {
    overflow: visible;
    svg {
      width: 30px;
      height: 30px;
    }
    &.transparent {
      background: transparent;
    }
  }
  .ant-tabs-ink-bar {
    display: none !important;
  }
  .ant-tabs-top-bar {
    border: 0;
    padding: 8px 2px;
    margin-bottom: 0;
  }
  .ant-tabs-tab {
    @include small-font;
    color: $white;
    border: 0;
    padding: 2px 10px;
    border-radius: 20px;
    background: $dark-gray;
    margin-right: 10px;
    &:hover {
      color: $white;
    }
  }
  .ant-tabs-tab-active {
    background: $blue;
    font-weight: 400;
  }
  .ant-tabs-nav-wrap {
    margin-bottom: 0;
  }
}