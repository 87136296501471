.dashboard-container {
  margin: 0;
  .dashboard-row {
    > div {
      height: 420px;
      overflow: hidden;

      &.live-monitoring-row {
        height: 75px;
      }
    }
  }
  .live-monitoring {
    display: flex;
    align-items: center;

    h3 {
      color: $white;
      font-size: 25px !important;
      padding: 0 30px 0 15px;
      margin: 0;
    }
  }
  .promote-btn-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 5px;
  }
  .ant-tabs-nav-scroll {
    text-align: center;
  }
  .your-order-card  {
    @include transparent-background;
    color: $white;
    .drive-logo {
      margin-top: 5px;
      @include large-font;
      svg {
        height: 35px;
        top: 0;
        left: -11px;
      }
    }
    .ant-card-body {
      padding: 0;
    }
    .card-scroll-content {
      height: 360px !important;
      position: relative;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      @include small-font;
      button {
        background: $blue;
        border-color: $blue;
      }
    }
    .ant-card-head-title {
      color: $white;
      font-size: 1.2rem;
    }
    button {
      &[disabled] {
        color: $white;
        opacity: 0.4;
      }
    }
  }
  .campaign-dash-icon {
    position: absolute;
    top: 5px;
    left: 3px;
  }
  .tab-car-icon {
    width: 25px !important;
    height: 25px !important;
  }
  .car-list-extra {
    color: $white;
    @include very-small-font;
    line-height: 1rem;
    margin-top: -5px;
    strong {
      font-weight: 500;
      @include small-font;
    }
  }
  .ant-list-item-action {
    margin-left: 0;
  }
  .dash-center-card {
    @include light-background-gradient;
    color: $white;
    height: 230px;
    text-align: center;
    position: relative;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    .ant-card-body {
      padding: 0;
      > div:first-child {
        margin-top: 8px;
        margin-left: 22px;
        margin-bottom: -5px;
        svg {
          top: 5px;
        }
      }
      > div:last-child {
        @include large-font;
        line-height: 1.5rem;
      }
    }
    .ant-divider {
      margin: 5px 0;
    }
  }
  .p-card-blue {
    @include extra-small-font;
    display: inline-block;
    border-radius: 5px;
    text-align: center;
    border: 2px solid $white;
    padding: 4px 0;
    > div:first-child {
      @include gray-background;
      border: 4px solid $blue;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      margin: 0 auto 2px;
      position: relative;
      svg {
        width: 35px;
        position: absolute;
        left: calc(50% - 15px);
        top: 0;
      }
    }
  }
  .g-card {
    display: inline-block;
    border-radius: 5px;
    border: 2px solid $white;
    text-align: center;
    svg {
      width: 45px;
      height: 65px;
    }
  }
  .top-audiences {
    color: $white;
    padding: .5rem;
    border-top: 1px solid $white;
    h3 {
      color: $white;
    }
    .top-audience-row {
      @include very-small-font;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 10px;
      > div {
        width: 40%;
        position: relative;
        margin-top: -4px;
        .label {
          position: absolute;
          right: 0;
          top: 17px;
          font-size: .5rem;
        }
      }
    }
  }
  .your-inventory-box,
  .top-ads-box {
    @include light-background-gradient;
    // padding: .5rem;
    color: $white;
    h3 {
      color: $white;
      font-weight: 400;
    }
    .ant-card-head-title,
    h4 {
      color: $white;
    }
    .dynamic-video-scroll-box {
      height: 379px;
    }
    .top-ads-filter {
      text-align: center;
      padding-bottom: 25px;
    }
  }
  .top-ads-filter {
    align-self: center;
  }
  .your-inventory-box {
    .inventory-scroll-box {
      height: 272px;

      .ant-list-item-action {
        padding-right: 10px;
      }

      .ant-list-item {
        cursor: pointer;
      }
    }
    .your-inventory-filter {
      display: flex;
      justify-content: space-between;
      padding-bottom: 16px;
    }
    .ant-empty-description {
      color: #fff;
    }
  }
  .activity-card {
    .activity-scroll-box {
      height: 185px;
    }
    .ant-tabs-bar {
      padding-left: 10px;
    }
  }
  .dynamic-video-row {
    @include small-font;
    padding-bottom: 10px;
    border-bottom: 1px solid $white;
    margin-bottom: 10px;
    cursor: pointer;
    img {
      background: $light-gray;
      width: 95%;
      margin: 5px 0 0 0;
    }
    .video-data-text {
      font-size: .7rem;
    }
    &:last-child {
      border-bottom: 0;
    }
  }
  .activity-row {
    border-top: 1px solid $white;
    color: $white;
    line-height: 1rem;
    padding: 5px 0 10px;
    @include very-small-font;
    font-weight: 500;
    .ant-progress-text {
      color: $white;
    }
    .ant-progress-circle  {
      background: $dark-gray;
      border-radius: 50%;
    }
    h4 {
      color: $white;
      margin: 5px 0;
      @include medium-font;
      font-weight: 400;
    }
    > div:first-child {
      text-align: center;
      margin-top: 20px;
    }
  }
  .inventory-scroll-box,
  .dynamic-video-scroll-box,
  .top-audiences-scroll-box,
  .activity-scroll-box {
    height: 188px;
    position: relative;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .top-audiences-scroll-box {
    height: 103px;
  }
  .dynamic-video-scroll-box {
    height: 142px;
  }
  .optimizations-card {
    height: 515px;
    @include light-background-gradient;
    .ant-tabs-nav-scroll {
      padding-left: 10px;
    }
    .ant-tabs-top-bar {
      padding: 10px 2px 15px;
    }
  }
  .media-icon {
    padding: 2px 0 5px;
    svg {
      height: 32px;
      margin-left: -18px;
    }
  }
  .live-monitoring-text {
    @include light-background-gradient;
    font-size: 1.5rem;
    height: 55px;
    align-items: center;
    justify-content: center;
    svg {
      height: 30px;
      margin-right: 0;
    }
  }
  .your-inventory-title,
  .top-performing-title {
    @include light-background-gradient;
    font-size: 1.5rem;
    height: 55px;
    align-items: center;
    color: $white;
    display: flex;
    padding-left: 10px;
    margin: 0;
    justify-content: center;
  }
  .your-inventory-title {
    justify-content: flex-start;
    font-size: 1.2rem;
    line-height: 20px;
    margin-top: 5px;
    height: auto;
    min-height: 36px;
    padding: 8px 12px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    position: relative;
    z-index: 9;

    button {
      font-size: 25px;
      text-transform: capitalize;
      padding: 0;
    }
  }
  .chart-top-text {
    @include light-background-gradient;
    font-size: 1.5rem;
    height: 55px;
    padding: 0 10px;
  }
  .right-top-icons {
    > * {
      width: 60px;
      margin: 0 5px;
    }
  }
  .views-chart {
    .center-data {
      font-size: .8rem !important;
      strong {
        font-size: 1rem !important;
      }
    }
  }
  .social-media-list {
    height: 400px;
    @include light-background-gradient;
    .ant-card {
      height: auto;
    }
    .space-between-row {
      position: relative;
      color: $white;
      padding: 10px 10px;
      align-items: flex-start;
      .anticon-ellipsis {
        position: absolute;
        top: 0;
        right: 5px;
        font-size: 1rem;
      }
      .ant-card-meta-avatar {
        padding-right: 2px;
      }
      .social-data {
        @include small-font;
        line-height: 0.8rem;
        margin-top: 2px;
        margin-right: 10px;
        
        > div {
          display: flex;
          justify-content: space-between;
          min-width: 130px;
          padding-bottom: 5px;
        }
      }
      .ant-card-meta-detail {
        line-height: 1rem;
        .ant-card-meta-title {
          margin-bottom: 0;
          @include medium-font;
        }
        .ant-card-meta-description {
          color: $white;
          margin-left: 2px;
          @include very-small-font;
        }
      }
    }
  }
  .small-activate-icon {
    width: 10px;
    height: 10px;
  }
  .activate-card {
    .ant-list-item {
      .ant-avatar-circle {
        svg {
          width: 60px;
          height: 60px;
          margin-top: -3px;
          margin-left: -5px;
        }
        .anticon-rise {
          svg {
            width: 35px;
            height: 35px;
            margin-top: 5px;
          }
        }
      }
    }
  }
  .signature-pad {
    overflow: hidden;
    p {
      padding: 5px 10px 0;
    }
    canvas {
      margin: 0 auto;
      background: #000000;
    }
  }
  .submit-success {
    background: $card-gray;
    padding: 40px 10px 0;
    height: 333px;
    > div:first-child {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    p {
      margin-top: 10px;
      color: $white;
      text-align: center;
    }
  }
  .inventory-pie-chart {
    .card-scroll-content {
      height: 225px !important;
      padding-top: 25px;
      .legend {
        position: static;
      }
    }
  }
  .activations-tab-card,
  .inventory-pie-chart,
  .operations-tab-card {
    .ant-card-loading-content {
      padding: 40px 5px 0;
    }
    .ant-card-head {
      border-bottom: 1px solid $light-white;
      .ant-avatar-circle.ant-avatar-icon {
        overflow: hidden;
      }
    }
  }
  .operations-car-icon {
    width: 30px !important;
    margin-top: 10px !important;
    margin-left: 0!important;
  }
  .all-list {
    height: 293px;
    position: relative;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    .ant-card-head {
      background: $campaign-creator-back;
    }
    .card-scroll-content {
      height: auto !important;
      overflow-y: visible;
    }
    .ant-card-meta-title {
      margin-top: 2px !important;
    }
    > div {
      border-bottom: 1px solid $white;
    }
  }
  @media only screen and (max-width: 576px) {
    .inventory-pie-chart {
      .slick-dots-bottom {
        bottom: -24px;
      }
    }
  }
}

#performance-chart {
  background: $card-gray;
}

.campaign-review-cart {
  .signature-pad {
    overflow: hidden;
    p:first-child {
      padding: 5px 10px 0;
      font-size: 20px !important;
    }
    canvas {
      margin: 0 auto;
      background: #000000;
    }
  }
}

.success-modal-root.top-ad-player {
  .ant-modal-content {
    height: auto !important;
  }
  .ant-modal-body {
    width: 100% !important;
  }
}


 
.scrollbar
{ 
	overflow-y: scroll; 
  height: 700px;  
  padding: 11px;  
  background-color: rgba(0, 0, 0, 0.35);  
  // border-radius: 5%;
} 
/*
 *  STYLE 1
 */
#style-1::-webkit-scrollbar-track
{
	box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 8px;
	background-color: #F5F5F5;
}

#style-1::-webkit-scrollbar
{
	width: 8px;
  height: 8px;
  border-radius: 8px; 
}

#style-1::-webkit-scrollbar-thumb
{
	border-radius: 8px;
	box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #a8a8a8;
}