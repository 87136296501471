.car-list-card {
  .ant-card {
    @include light-background-gradient;
    color: $white;
    .ant-card-head {
      padding: 0 6px;
      height: 42px;
    }
    .ant-card-body {
      padding: 0;
    }
    .ant-card-meta-title {
      color: $white;
      margin-top: 5px;
    }
    .ant-card-meta-avatar {
      margin-top: 5px;
      padding-right: 5px;
    }
    .ant-avatar-icon,
    .ant-avatar-circle {
      overflow: visible;
      svg {
        width: 30px;
        height: 30px;
      }
      &.transparent {
        background: transparent;
      }
    }
  }
  .ant-list-item-meta-title {
    color: $white;
  }
  .ant-list-item {
    padding: 10px 5px;
  }
  .ant-list-item-meta-avatar {
    margin-right: 10px;
  }
  .top-bar-list {
    display: flex;
    flex-direction: row;
    > div {
      @include very-small-font;
      font-weight: 500;
      padding: 2px;
      flex-grow: 1;
      color: $white;
      text-align: center;
      &:first-child {
        background: $green;
        + div {
          background: $yellow;
          + div {
            background: $red;
          }
        }
      }
    }
  }
  .card-scroll-content {
    height: 169px;
    position: relative;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}