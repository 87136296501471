.MuiButtonBase-root{
    color: #00a6e6 !important;
}

.MuiInputBase-input{
    color: black !important;
}
.MuiDataGrid-columnHeaderTitle{
    font-size: large;
}
.MuiButton-label{
    background-color: #00a6e6 !important;
    color: white;
}
.MuiDataGrid-columnHeaderTitle {
    color: white;
}
.MuiDataGrid-cell{
    color: white;
}
.MuiTablePagination-root{
    color: white !important;
}
.MuiTablePagination-menuItem{
    display: block !important;
}
.MuiTablePagination-selectIcon{
    color: white !important;
}
.MuiInputBase-input{
    color: white !important;
    font-size: 22px !important;
}
.gridButtons{
    width: 80px !important;
    min-width: 80px !important;
    border-radius: 20px !important;
}





.custom-pop .MuiDataGrid-root--densityStandard{
    border:0 !important;
}

.custom-pop .ant-btn-primary {
    height: 32px !important;
    font-size: 15px !important;
}

.custom-pop .gridButtons {
    width: 65px !important;
    min-width: 65px !important;
}
 
.custom-pop.ant-modal.campaign-creator-root{
    width:900px !important;
}
.custom-pop .MuiDataGrid-root--densityStandard .MuiDataGrid-columnHeader{
    padding: 0px 16px !important;
}
.custom-pop .MuiDataGrid-toolbarContainer {
    padding: 4px 10px 0px !important;
}

.custom-pop .MuiDataGrid-root--densityStandard .MuiDataGrid-cell{
    padding: 0px 16px !important;
}

.custom-pop .campaign-creator-root .ant-modal-content { 
    min-height: 92% !important;
    max-height: 92% !important;
}
.custom-pop .ant-modal-body p {
    font-size: 18px !important;
}
.custom-pop .MuiInputBase-input { 
    font-size: 18px !important;
}

.custom-pop .MuiTablePagination-root .MuiTablePagination-actions { 
    margin-bottom: auto !important;
}

.custom-pop .MuiTablePagination-root { 
    margin-top: 4px;
    margin-bottom: 8px;
}
.custom-pop .ant-modal-header {
    padding: 22px !important;
}
.custom-pop .MuiInputBase-root.MuiInputBase-colorPrimary{
    margin-bottom: 8px !important;
}
.custom-pop .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiTablePagination-selectIcon.MuiSelect-icon.MuiSelect-iconStandard{
    margin-top: -3px;
}

.custom-pop .MuiDataGrid-virtualScroller::-webkit-scrollbar-track
{
	box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 8px;
	background-color: #F5F5F5;
}

.custom-pop .MuiDataGrid-virtualScroller::-webkit-scrollbar
{
	width: 8px;
  height: 8px;
  border-radius: 8px; 
}

.custom-pop .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb
{
	border-radius: 8px;
	box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #a8a8a8;
}