body {
  @include medium-font;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.arrow-up {
  color: $green-arrow;
}

.arrow-down {
  color: $red-arrow;
}

.ant-switch-checked {
  background-color: $blue;
}

.bordered {
  border: 1px solid $white;
  border-radius: 2px;
}

.drive-logo {
  display: inline-block;
  position: relative;
  font-size: 2rem;
  color: $white;
  padding-left: 30px;
  svg {
    height: 50px;
    position: absolute;
    top: -16px;
    left: calc(50% - 56px);
  }
}

.space-between {
  @include space-between;
}

.space-content {
  > * {
    margin: 10px 0;
  }
}

.space-between-row {
  @include space-between;
  padding: 5px 10px;
}

.flex {
  display: flex;
}

.flex-end {
  justify-content: flex-end;
}

.flex-spaced {
  display: flex;
  > * {
    margin-right: 5px;
  }
}

.centered-row {
  display: flex;
  justify-content: center;
  padding: 5px 10px;
  > * {
    margin-right: 5px;
  }
}

.auto-height {
  height: auto !important;
}

.hidden {
  display: none;
}

.light-back {
  @include light-background-gradient;
}

.dark-back {
  @include dark-background-gradient;
}

.gray-back {
  @include gray-background;
}
.blue-back {
  @include blue-background;
}

.dark-blue-back {
  @include dark-blue-background;
}

.transparent-back {
  @include transparent-background;
}

.border-top {
  border-top: 1px solid $light-white;
}

.border-bottom {
  border-bottom: 1px solid $light-white;
}

.border-left {
  border-left: 1px solid $light-white;
}

.border-right {
  border-right: 1px solid $light-white;
}

.pad-top {
  padding-top: 10px;
}

.pad-bottom {
  padding-bottom: 10px;
}

.pad-bottom-15 {
  padding-bottom: 15px;
}

.pad-left {
  padding-left: 10px;
}

.pad-right {
  padding-right: 10px;
}

.ant-card {
  border-radius: 0;
}

.pad-top-2x {
  padding-top: 20px;
}

@media only screen and (max-width: 768px) {
  .flex-end {
    justify-content: flex-start;
  }
}

.pf-c-backdrop,
.ant-modal-wrap {
  backdrop-filter: blur(2px);
}

.pf-c-wizard__title {
  color: $white;
}

.pf-c-radio.pf-m-standalone {
  margin-right: 10px;
}

.ant-btn-primary {
  border-color: $blue !important;
  background: $blue !important;
  color: $white !important;
  span {
    background: $blue;
  }

  &[disabled] {
    color: #fff;
    opacity: 0.4;
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $blue;
  border-color: $blue;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after,
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after,
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: rgba(255, 255, 255, 0.75);
  background-color: rgba(255, 255, 255, 0.75);
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after,
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot,
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: $blue;
  background-color: $blue;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(255, 255, 255, .65);
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  color: rgba(255, 255, 255, .85);
}

.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  color: rgba(255, 255, 255, .45);
}

div.cart-row {
  color: $white;
  padding: 15px;
  position: relative;

  .ant-card-meta-avatar {
    margin-top: 10px;
    padding-right: 10px;
  }

  .ant-card-meta-title {
    color: $white;
    margin-bottom: 0 !important;

    > div:first-child {
      display: flex;
      justify-content: space-between;
      font-weight: 400;
      font-size: 18px;
      strong {
        font-weight: 700;
      }
    }

    > .anticon-delete {
      position: absolute;
      right: 10px;
      top: 46px;
      color: $red !important;
    }
  }

  .ant-card-meta-description {
    color: $white;
    font-size: 12px;
 }
}

.frf-water {
  display: none;
}

#feedbackEmail,
#feedbackMsg {
  color: rgb(21, 21, 21);

  &::selection {
    color: $white;
    background-color: rgb(21, 21, 21) !important;
  }
}

.disclaimer {
  font-size: 10px;
}

.ant-modal-body .disclaimer {
  font-size: 12px !important;
}
