.login-page {
  color: rgb(21, 21, 21);

  header, footer {
    display: none;
  }

  .pf-c-login__container {
    display: flex;
    justify-content: center;
  }

  .login-drive-logo {
    margin-top: 50px;
    height: 107px;
  }

  .login-context-text {
    text-align: center;
    font-weight: 700;
    font-size: 36px;
    margin: 30px 0 0;
  }

  #pf-login-username-id,
  #pf-login-password-id {
    color: rgb(21, 21, 21);
  }

  .loading-spinner {
    display: flex;
    height: 418px;
    justify-content: center;
    align-items: center;
  }

  .login-autocomplete {
    margin: 50px 0 258px;
  }

  input.pf-c-form-control {
    border: 1px solid rgb(21, 21, 21) !important;

    + button {
      border: 1px solid rgb(21, 21, 21) !important;
      height: 36px;
    }
  }

  * {
    &::selection {
      color: $white;
      background-color: rgb(21, 21, 21) !important;
    }
  }

  a {
    color: rgb(0, 102, 204);

    &:hover {
      color: rgb(0, 64, 128);
      text-decoration: underline;
    }
  }
}

.ant-message-success .anticon {
  color: #52c41a !important;
}

.ant-message-error .anticon {
  color: #f5222d !important;
}

.custom-loading{  
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0); 
    left: 50%; 
    position: fixed;
    top: 50%;
    z-index: 9999999;
}