.pie-chart-carousel {
  &.loading {
    .slick-dots {
      display: none !important;
    }
  }
  .slick-dots-bottom {
    bottom: -30px;
  }
}
.pf-c-chart {
  tspan {
    fill: $white !important;
  }
}
.ant-card.pie-chart-card {
  @include transparent-background;
  color: $white;
  height: 230px;
  .transparent {
    background: transparent !important;
  }
  .ant-card-body {
    padding: 0;
  }
  .ant-card-meta-title {
    color: $white;
    margin-top: 5px;
    font-size: 1.2rem;
  }
  .ant-card-meta-avatar {
    margin-top: 5px;
    padding-right: 5px;
  }
  .ant-card-meta-description {
    color: $white;
    @include small-font;
  }
  .ant-avatar-icon,
  .ant-avatar-circle {
    background: $white;
    svg {
      width: 30px;
      height: 30px;
    }
  }
  .card-scroll-content {
    height: 160px;
    position: relative;
  }
  &.car-dash-card {
    .ant-card-meta-description {
      @include very-small-font;
    }
    .car-dash-icon {
      margin-top: 6px;
    }
    .card-scroll-content {
      height: 167px;
    }
  }
  .white-circle {
    position: absolute;
    background: $white;
    height: 124px;
    width: 123px;
    border-radius: 50%;
    top: calc(50% - 62px);
    left: calc(50% - 62px);
    z-index: 0;
  }
  .center-data {
    @include medium-font;
    position: absolute;
    background: $dark-gray;
    height: 98px;
    width: 98px;
    border-radius: 50%;
    top: calc(50% - 49px);
    left: calc(50% - 49px);
    z-index: 0;
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    span {
      margin-bottom: -4px;
    }
    strong {
      @include very-large-font;
      font-weight: bold;
    }
  }
  .legend {
    @include extra-small-font;
    font-weight: 500;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-top: 10px;
    > div {
      margin-right: 5px;
      div {
        display: inline-block;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        margin-right: 5px;
      }
    }
  }
  .pull-up {
    margin-top: -5px;
    position: relative;
  }
}