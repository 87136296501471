@mixin base-background {
  background: $new-black;
}

@mixin light-background-gradient {
  @include base-background;
}

@mixin dark-background-gradient {
  @include base-background;
}

@mixin gray-background {
  background-color: rgba(0, 0, 0, 0.35);
}

@mixin blue-background {
  background-color: rgba(0, 176, 239, 0.35);
}

@mixin dark-blue-background {
  background-color: $dark-blue;
}

@mixin transparent-background {
  background-color: transparent;
}

@mixin space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin extra-large-font {
  font-size: 2rem;
}

@mixin very-large-font {
  font-size: 1.4rem;
}

@mixin large-font {
  font-size: 1.2rem;
}

@mixin medium-font {
  font-size: 1rem;
}

@mixin small-font {
  font-size: 0.8rem;
}

@mixin very-small-font {
  font-size: 0.7rem;
}

@mixin extra-small-font {
  font-size: 0.5rem;
}