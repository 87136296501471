$white: #fff;
$light-white: rgba(255, 255, 255, 0.25);
$blue: #00a6e6;
$green: #a4df23;
$green-arrow: #b3e846;
$red-arrow: #DD5732;
$yellow: #ffda05;
$red: #e02834;
$dark-blue: #344557;
$card-gray: #343943;
$dark-gray: rgb(68, 68, 68);
$light-gray: #5F676B;
$orange: #FFB424;
$campaign-creator-back: #3b4958;
$new-gray: rgb(69, 69, 69);
$new-black: rgb(22, 22, 22);
